import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Cookies } from 'HOC/withCookies/Cookies';
import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { getUserLocalStorage, profilePath } from 'util/util';
import { compose } from 'redux';
import { connect } from 'react-redux';

const COOKIE_NAME = 'cookies-accept';
const PLUS_ONE_MONTH = 3600 * 24 * 30;

export const withCookies = (Wrapped) => {
    const Wrapper = props => {
        const { history, userId } = props;
        const [cookies, setCookie] = useCookies([COOKIE_NAME]);

        const handleAccept = () => {
            setCookie(COOKIE_NAME, true, { path: '/', maxAge: PLUS_ONE_MONTH });
        };

        const handleConfigure = () => {
            const user = getUserLocalStorage();
            setCookie(COOKIE_NAME, true, { path: '/', maxAge: PLUS_ONE_MONTH });
            history.push(profilePath(user, userId));
        };

        return (
            <Fragment>
                <Wrapped
                    {...props}
                />
                { !cookies[COOKIE_NAME] && <Cookies
                    handleAccept={handleAccept}
                    handleConfigure={handleConfigure}
                /> }
            </Fragment>
        );
    };

    const mapStateToProps = (state) => ({
        userId: state.user.user.id,
    });

    Wrapper.propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func,
        }).isRequired,
        userId: PropTypes.string.isRequired,
    };

    return compose(
        withRouter,
        connect(mapStateToProps)
    )(Wrapper);
};

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { SupportModal } from "components/Modal/supportModal";
import { SIconButtonMail } from "components/IconButton";
import ChatbotBox from "components/Modal/ChatbotBox";

export const SupportContainer = ({ supportMail }) => (
  <Fragment>
    <SupportModal onCancel={supportMail.toggle} isOpen={supportMail.isOpened} />
    {/* <SIconButtonMail onClick={supportMail.toggle} path="ic-mail" /> */}
    <ChatbotBox iframeSrc="https://copilotstudio.microsoft.com/environments/Default-0159e9d0-09a0-4edf-96ba-a3deea363c28/bots/crad4_cbrExplorer/webchat?__version__=2" />
  </Fragment>
);

SupportContainer.propTypes = {
  supportMail: PropTypes.object.isRequired,
};

import { TYPES } from 'actionTypes/tags';

const defaultState = {
    tags: [],
    getTagsPending: false,
    createTagsPending: false
};

export const reducer = (state = defaultState, action) => {
    switch(action.type) {
        case TYPES.GET_TAGS.PENDING:
            return {
                ...state,
                getTagsPending: true,
            };
        case TYPES.GET_TAGS.FULFILLED:
            return {
                ...state,
                tags: action.payload,
                getTagsPending: false,
            };
        case TYPES.GET_TAGS.REJECTED:
            return {
                ...state,
                getTagsPending: false,
            };
        case TYPES.CREATE_TAG.PENDING:
            return {
                ...state,
                createTagPending: true,
            };
        case TYPES.CREATE_TAG.FULFILLED:
            return {
                ...state,
                createTagPending: false,
            };
        case TYPES.CREATE_TAG.REJECTED:
            return {
                ...state,
                createTagPending: false,
            };
        default:
            return state;
    }
};

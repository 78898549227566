import { TYPES } from 'actionTypes/breadcrumbs';

const defaultState = {
    getBreadcrumbsPending: false,
    breadcrumbs: ''
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case TYPES.GET_BREADCRUMBS.PENDING:
            return {
                ...state,
                getBreadcrumbsPending: true,
            };
        case TYPES.GET_BREADCRUMBS.FULFILLED:
            return {
                ...state,
                getBreadcrumbsPending: false,
                breadcrumbs: action.breadcrumbs
            };
        case TYPES.GET_BREADCRUMBS.REJECTED:
            return {
                ...state,
                getBreadcrumbsPending: false,
            };
        default:
            return state;
    }
};

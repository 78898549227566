import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { STYLES_CONSTANTS } from "styles/constants/constants";

const SInput = styled.input`
  height: 32px;
  padding: 3px 16px;
  border: 1px solid ${STYLES_CONSTANTS.COLORS.LIGHT_GREY};
  border-radius: 24px;
  font-size: ${STYLES_CONSTANTS.FONTSIZE.default};
  line-height: 18px;
  width: 100%;

  &:disabled {
    background-color: ${STYLES_CONSTANTS.COLORS.LIGHT_GREY};
  }
`;

export const Input = (props) => {
  return <SInput id={props.id} {...props.input} {...props} />;
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
};

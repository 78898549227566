import React, { useEffect, useState, Fragment } from "react";
import { node, func, shape } from "prop-types";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal, useAccount } from "@azure/msal-react";
import axios from "axios";
import NoAccessView from "views/noAccess";
import {
  login as loginAction,
  setUserToState as setUserToStateAction,
} from "actions/user";
import { getMyProfile as getMyProfileAction } from "actions/users";
import { getMyNotifications as getMyNotificationsAction } from "actions/notifications";
import { getFilesRoot as getFilesRootAction } from "actions/files";
import { setSocket as setSocketAction } from "actions/socket";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import ReactGA from 'react-ga';
import { GA_CATEGORIES, GA_EVENTS } from "constants/gaEvents";
import io from "socket.io-client";
import { CONFIG } from "../../config";
import { LS_KEYS } from "../../constants/LSKeys";
import { SkeletonOrigin } from "../../components/skeleton/Origin";
import { gtag } from "ga-gtag";
import { getMyFavorites } from "../../actions/favorites";

const EMPTY_GROUPS = "Empty groups";

const _AuthLogin = ({
  children,
  sendLoginRequest,
  history,
  getFilesRoot,
  getMyNotifications,
  setSocket,
  getMyProfile,
  setUserToState,
  getMyFavorites,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [needsPermissions, setNeedsPermissions] = useState(false);
  const [hasPermission, setHasPermission] = useState(true);
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});

  let interceptorID;

  const connectSocket = (token) => {
    const socket = io(CONFIG.SOCKET_URL, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
      secure: true,
      withCredentials: true,

      reconnectionAttempts: 15,
      reconnectionDelay: 3000,
      transports: ["polling", "websocket"],
    });
    setSocket({ socket });
  };

  const requestInterceptor = async (config, sessionToken) => {
    /* eslint-disable no-param-reassign */
    config.headers.common.Authorization = `Bearer ${sessionToken}`;
    return config;
  };

  const createInterceptor = (sessionToken) => {
    if (interceptorID !== undefined)
      axios.interceptors.request.eject(interceptorID);
    interceptorID = axios.interceptors.request.use((config) =>
      requestInterceptor(config, sessionToken)
    );
  };

  const createSetup = async (res, sessionToken) => {
    if (res && res.data && res.data.groups && res.data.groups.length === 0) {
      setNeedsPermissions(true);
      const res2 = await axios.post("/users/permissions");

      location.reload();
      // setHasPermission(false);
      throw new Error(EMPTY_GROUPS);
    }

    try {
      // if (window.location.hash === "#/" && !res.data.isAdmin) {
      //   const res2 = await axios.get("/files/root-modules");
      //   const knowledge = res2.data.files.find(
      //     (item) => item.name === "Knowledge"
      //   );
      //   if (knowledge && knowledge.children && knowledge.children.length > 0) {
      //     // * INFO: removed redirect
      //     //   history.push(`${PATH.BUSINESS}/`);
      //   }
      // }
      // eslint-disable-next-line no-empty
    } catch (e) {}
    await getFilesRoot();
    getMyNotifications();
    getMyFavorites();
    // await Promise.all([getMyNotifications(), getMyFavorites()]);
    connectSocket(sessionToken);

    if (res.data.id) {
      // ReactGA.set({
      //     dimension1: res.data.id,
      //     dimension2: 'WEB',
      // });
      gtag("set", {
        dimension1: res.data.id,
        dimension2: "WEB",
      });
    } else {
      window[`ga-disable-${CONFIG.GOOGLE_ANALYTICS_ID}`] = true;
    }

    // ReactGA.event({
    //     category: GA_CATEGORIES.LOGIN,
    //     action: GA_EVENTS.LOGIN_SUCCESS,
    //     label: res.data.id,
    // });
    gtag("event", GA_EVENTS.LOGIN_SUCCESS, {
      category: GA_CATEGORIES.LOGIN,
      action: GA_EVENTS.LOGIN_SUCCESS,
      label: res.data.id,
    });
  };
  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const onLogin = async (externalResponse) => {
    // en lugar de hacer una petición a la API, guardo la jwt en localstorage y la seteo en el interceptor
    const { idToken } = externalResponse;
    const res = await sendLoginRequest(idToken);

    const { accessToken: sessionToken } = res.data;
    localStorage.setItem(LS_KEYS.SESSION_TOKEN, sessionToken);
    location.reload();
  };

  const getExternalToken = async () => {
    const request = {
      scopes: CONFIG.B2C.graphScopes,
      account,
    };

    try {
      const response = await instance.acquireTokenSilent(request);
      await onLogin(response);
    } catch (e) {
      console.log({ e });
      if (e && "response" in e && e.response.status == 405) {
        setHasPermission(false);
      }
      if (e && e.error && e.error.message === EMPTY_GROUPS) {
      } else {
        instance.acquireTokenRedirect(request);
      }
    }
  };

  const responseErrorInterceptor = async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retryed) {
      originalRequest._retryed = true;
      await getExternalToken();
      originalRequest.headers.Authorization = `Bearer ${localStorage.getItem(
        LS_KEYS.SESSION_TOKEN
      )}`;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  };

  const login = async () => {
    // Get session token from local storage and set error interceptor
    const sessionToken = localStorage.getItem(LS_KEYS.SESSION_TOKEN);
    const redirectURI = localStorage.getItem(LS_KEYS.REDIRECT_URI)
      ? localStorage.getItem(LS_KEYS.REDIRECT_URI)
      : getCookie("redirectURI");
    axios.interceptors.response.use(
      (response) => response,
      responseErrorInterceptor
    );

    if (sessionToken) {
      // If session token is in local storage, get user data and redirect to dashboard
      createInterceptor(sessionToken);
      const data = await getMyProfile();
      if (!data) {
        logout();
      }
      await Promise.all([
        setUserToState(data),
        createSetup({ data }, sessionToken),
      ]);
      // Mobile login implementation
      if (redirectURI) {
        //  window.location.href = `${redirectURI}?accessToken=${sessionToken}`;
        history.push(`/external/completed`);
      } else {
        var userAgent = navigator.userAgent || navigator.vendor;

        if (
          /android/i.test(userAgent) ||
          (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
        ) {
          localStorage.setItem(LS_KEYS.APP_REDIRECT, location.hash);
          history.push(`/external/app`);
        }
      }
      setIsLoading(false);
    } else {
      if (!redirectURI) {
        var userAgent = navigator.userAgent || navigator.vendor;

        if (
          /android/i.test(userAgent) ||
          (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
        ) {
          localStorage.setItem(LS_KEYS.APP_REDIRECT, location.hash);
          history.push(`/external/app`);
        }
      }
      try {
        await getExternalToken();
      } catch (e) {
        console.log({ erro: e });
      }
    }
  };

  const logout = () => {
    localStorage.removeItem(LS_KEYS.SESSION_TOKEN);
    instance.logoutRedirect();
  };

  useEffect(() => {
    if (isLoading && inProgress === InteractionStatus.None) {
      // try login
      login();
    }
    // this deps are stored in cache and won't come from any request
  }, [account, inProgress, instance, isLoading]);

  if (!hasPermission) {
    return <NoAccessView logoutCallback={logout} />;
  }

  return (
    <Fragment>
      {isLoading ? (
        <SkeletonOrigin needsPermissions={needsPermissions} />
      ) : (
        children
      )}
    </Fragment>
  );
};

_AuthLogin.propTypes = {
  children: node.isRequired,
  sendLoginRequest: func.isRequired,
  getFilesRoot: func.isRequired,
  getMyNotifications: func.isRequired,
  setSocket: func.isRequired,
  getMyProfile: func.isRequired,
  setUserToState: func.isRequired,
  history: shape({
    push: func,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  sendLoginRequest: (graphToken) => dispatch(loginAction(graphToken)),
  getMyNotifications: () => dispatch(getMyNotificationsAction()),
  getFilesRoot: () => dispatch(getFilesRootAction()),
  setSocket: (socket) => dispatch(setSocketAction(socket)),
  getMyProfile: () => dispatch(getMyProfileAction()),
  setUserToState: (user) => dispatch(setUserToStateAction(user)),
  getMyFavorites: () => dispatch(getMyFavorites()),
});

export const AuthLogin = withRouter(
  connect(null, mapDispatchToProps)(_AuthLogin)
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';


export const withModal = (Wrapped, names) => {
    return class Wrapper extends Component {
        static propTypes = {
            modalProps: PropTypes.object,
        };

        static defaultProps = {
            modalProps: {},
        };

        state = {
            opened: [],
        };

        toggleModalFunction = name => {
            if (this.state.opened.includes(name)) {
                this.setState({ opened: this.state.opened.filter(o => o !== name) });
            } else {
                this.setState({ opened: [...this.state.opened, name] });
            }
        };

        render() {
            const modalProps = names.reduce((prev, modal) => {
                return {
                    ...prev,
                    [modal]: {
                        toggle: () => this.toggleModalFunction(modal),
                        isOpened: this.state.opened.includes(modal),
                    }
                };
            }, {
                ...this.props.modalProps
            });
            return (
                <Wrapped
                    {...this.props}
                    modalProps={modalProps}
                />
            );
        }
    };
};

import { LEGAL_NOTICE_FDL_ACTIONS } from 'actionTypes/legalNotice';

const defaultState = {
    createLegalNoticeFdlPending: false,
    updateLegalNoticeFdlPending: false,
    getLegalNoticeFdlPending: false,

    legalNotice: {

    }
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case LEGAL_NOTICE_FDL_ACTIONS.CREATE_LEGAL_NOTICE_FDL.PENDING:
            return {
                ...state,
                createLegalNoticeFdlPending: true,
            };
        case LEGAL_NOTICE_FDL_ACTIONS.CREATE_LEGAL_NOTICE_FDL.FULFILLED:
            return {
                ...state,
                createLegalNoticeFdlPending: false,
            };
        case LEGAL_NOTICE_FDL_ACTIONS.CREATE_LEGAL_NOTICE_FDL.REJECTED:
            return {
                ...state,
                createLegalNoticeFdlPending: false,
            };

        case LEGAL_NOTICE_FDL_ACTIONS.UPDATE_LEGAL_NOTICE_FDL.PENDING:
            return {
                ...state,
                updateLegalNoticeFdlPending: true,
            };
        case LEGAL_NOTICE_FDL_ACTIONS.UPDATE_LEGAL_NOTICE_FDL.FULFILLED:
            return {
                ...state,
                updateLegalNoticeFdlPending: false,
            };
        case LEGAL_NOTICE_FDL_ACTIONS.UPDATE_LEGAL_NOTICE_FDL.REJECTED:
            return {
                ...state,
                updateLegalNoticeFdlPending: false,
            };

        case LEGAL_NOTICE_FDL_ACTIONS.GET_LEGAL_NOTICE_FDL.PENDING:
            return {
                ...state,
                getLegalNoticeFdlPending: true,
            };
        case LEGAL_NOTICE_FDL_ACTIONS.GET_LEGAL_NOTICE_FDL.FULFILLED:
            return {
                ...state,
                legalNotice: action.payload,
                getLegalNoticeFdlPending: false,
            };
        case LEGAL_NOTICE_FDL_ACTIONS.GET_LEGAL_NOTICE_FDL.REJECTED:
            return {
                ...state,
                legalNotice: action.payload,
                getLegalNoticeFdlPending: false,
            };

        default:
            return state;
    }
};

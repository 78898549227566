import { createAsyncActionConstants } from 'util/util';

export const TYPES = {
    CREATE_SECTION: createAsyncActionConstants('CREATE_SECTION'),
    GET_SECTIONS: createAsyncActionConstants('GET_SECTIONS'),
    DELETE_SECTION: createAsyncActionConstants('DELETE_SECTION'),
    GET_SECTION: createAsyncActionConstants('GET_SECTION'),
    UPDATE_SECTION: createAsyncActionConstants('UPDATE_SECTION'),
    CREATE_CARD: createAsyncActionConstants('CREATE_CARD'),
    GET_CARDS: createAsyncActionConstants('GET_CARDS'),
    DELETE_CARD: createAsyncActionConstants('DELETE_CARD'),
    GET_CARD: createAsyncActionConstants('GET_CARD'),
    UPDATE_CARD: createAsyncActionConstants('UPDATE_CARD'),
    GET_SECTIONS_WITH_CARDS: createAsyncActionConstants('GET_SECTIONS_WITH_CARDS'),
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SocketProvider } from 'socket.io-react';
import Sound from 'react-sound';
import { getMyNotifications } from 'actions/notifications';

class _SocketContainer extends Component {
    static propTypes = {
        socket: PropTypes.object.isRequired,
        children: PropTypes.node.isRequired,
        getMyNotifications: PropTypes.func.isRequired,
    };

    state = {
        playSound: Sound.status.STOPPED
    };

    componentDidMount() {
        this.handleSocket();
    }

    handleSocket = () => {
        const { socket } = this.props;
        socket.on('NOTIFICATION', () => {
            this.props.getMyNotifications();
            this.setState({ playSound: Sound.status.PLAYING });
        });
    };

    stopPlaying = () => {
        this.setState({ playSound: Sound.status.STOPPED });
    };

    render() {
        return (
            <SocketProvider socket={this.props.socket}>
                <Sound
                    url={'/assets/sounds/notificationSound.mp3'}
                    playStatus={this.state.playSound}
                    onFinishedPlaying={this.stopPlaying}
                />
                {this.props.children}
            </SocketProvider>
        );
    }
}

const mapStateToProps = state => ({
    socket: state.socket.socket.socket,
});

const mapDispatchToProps = dispatch => ({
    getMyNotifications: () => dispatch(getMyNotifications()),
});

export const SocketContainer = connect(mapStateToProps, mapDispatchToProps)(_SocketContainer);

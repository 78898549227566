import { createAsyncActionConstants } from "util/util";

export const AINEWS_ACTION_TYPES = {
  CREATE_AINEWS: createAsyncActionConstants("CREATE_AINEWS"),
  GET_AINEWS: createAsyncActionConstants("GET_AINEWS"),
  GET_AISTORIES: createAsyncActionConstants("GET_AISTORIES"),
  GET_AILINKS: createAsyncActionConstants("GET_AILINKS"),
  GET_HIGHLIGHTED_AINEWS: createAsyncActionConstants("GET_HIGHLIGHTED_AINEWS"),
  GET_SINGLE_AINEWS: createAsyncActionConstants("GET_SINGLE_AINEWS"),
  UPDATE_AINEWS: createAsyncActionConstants("UPDATE_AINEWS"),
  DELETE_AINEWS: createAsyncActionConstants("DELETE_AINEWS"),
  CLEAR_AINEWS: "CLEAR_AINEWS",
};

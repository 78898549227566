import axios from "axios";
import { API_URLS } from "constants/apiUrl";
import { FAVORITES_ACTION_TYPES } from "actionTypes/favorites";

export const getMyFavorites =
  (params = { page: 1, limit: 10 }) =>
  async (dispatch) => {
    dispatch({
      type: FAVORITES_ACTION_TYPES.GET_FAVORITES.PENDING,
    });
    try {
      const response = await axios.get(API_URLS.GET_FAVORITES, {
        params,
      });

      dispatch({
        type: FAVORITES_ACTION_TYPES.GET_FAVORITES.FULFILLED,
        payload: response.data.favorites,
      });
    } catch (error) {
      dispatch({
        type: FAVORITES_ACTION_TYPES.GET_FAVORITES.REJECTED,
      });
      throw error;
    }
  };
export const getFavoritesRecommendations = (params) => async (dispatch) => {
  dispatch({
    type: FAVORITES_ACTION_TYPES.GET_FAVORITES_RECOMMENDATIONS.PENDING,
  });
  try {
    const response = await axios.get(`${API_URLS.GET_FAVORITES}/recommended`, {
      params,
    });
    dispatch({
      type: FAVORITES_ACTION_TYPES.GET_FAVORITES_RECOMMENDATIONS.FULFILLED,
      payload: response.data.results,
    });
  } catch (error) {
    dispatch({
      type: FAVORITES_ACTION_TYPES.GET_FAVORITES_RECOMMENDATIONS.REJECTED,
    });
    throw error;
  }
};
export const addToFavorites = (fileId) => async (dispatch) => {
  dispatch({
    type: FAVORITES_ACTION_TYPES.ADD_FAVORITES.PENDING,
    payload: { id: fileId },
  });
  try {
    const response = await axios.post(API_URLS.GET_FAVORITES, {
      fileId,
    });

    dispatch({
      type: FAVORITES_ACTION_TYPES.ADD_FAVORITES.FULFILLED,
    });
  } catch (error) {
    dispatch({
      type: FAVORITES_ACTION_TYPES.ADD_FAVORITES.REJECTED,
    });
    throw error;
  }
};

export const deleteFromFavorites = (fileId) => async (dispatch) => {
  dispatch({
    type: FAVORITES_ACTION_TYPES.ADD_FAVORITES.PENDING,
    payload: { id: fileId },
  });
  try {
    const response = await axios.delete(API_URLS.GET_FAVORITES + "/" + fileId);

    dispatch({
      type: FAVORITES_ACTION_TYPES.ADD_FAVORITES.FULFILLED,
    });
  } catch (error) {
    dispatch({
      type: FAVORITES_ACTION_TYPES.ADD_FAVORITES.REJECTED,
    });
    throw error;
  }
};

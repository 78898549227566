import { AINEWS_ACTION_TYPES } from "actionTypes/aimodule";

const defaultState = {
  getAINewsPending: false,
  getHighlightedAINewsPending: false,
  getSingleAINewsPending: false,
  createAINewsPending: false,
  updateAINewsPending: false,
  deleteAINewsPending: false,

  AInews: {
    results: [],
    page: 1,
    limit: 20,
    count: 0,
  },
  highlightedAINews: {
    results: [],
    page: 1,
    limit: 10,
    count: 0,
  },
  singleAINews: {
    news: {
      file: {},
    },
    groups: [],
    tags: [],
  },
  AInewsResults: [],

  getAILinksPending: false,
  AILinks: {
    results: [],
    page: 1,
    limit: 20,
    count: 0,
  },
  AILinksResults: [],
  getAIStoriesPending: false,
  AIStories: {
    results: [],
    page: 1,
    limit: 10,
    count: 0,
  },
  AIStoriesResults: [],
};

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case AINEWS_ACTION_TYPES.CREATE_AINEWS.PENDING:
      return {
        ...state,
        createAINewsPending: true,
      };
    case AINEWS_ACTION_TYPES.CREATE_AINEWS.FULFILLED:
      return {
        ...state,
        createAINewsPending: false,
      };
    case AINEWS_ACTION_TYPES.CREATE_AINEWS.REJECTED:
      return {
        ...state,
        createAINewsPending: false,
      };
    case AINEWS_ACTION_TYPES.GET_AINEWS.PENDING:
      return {
        ...state,
        getAINewsPending: true,
      };
    case AINEWS_ACTION_TYPES.GET_AINEWS.FULFILLED: {
      return {
        ...state,
        getAINewsPending: false,
        AInews: action.payload,
        AInewsResults: action.payload.results,
      };
    }
    case AINEWS_ACTION_TYPES.CLEAR_AINEWS: {
      return {
        ...state,
        getAINewsPending: false,
        AInews: [],
        AInewsResults: [],
      };
    }
    case AINEWS_ACTION_TYPES.GET_AINEWS.REJECTED:
      return {
        ...state,
        getAINewsPending: false,
      };
    case AINEWS_ACTION_TYPES.GET_HIGHLIGHTED_AINEWS.PENDING:
      return {
        ...state,
        getHighlightedAINewsPending: true,
      };
    case AINEWS_ACTION_TYPES.GET_HIGHLIGHTED_AINEWS.FULFILLED:
      return {
        ...state,
        getHighlightedAINewsPending: false,
        highlightedAINews: action.payload,
      };

    case AINEWS_ACTION_TYPES.GET_HIGHLIGHTED_AINEWS.REJECTED:
      return {
        ...state,
        getHighlightedAINewsPending: false,
      };
    case AINEWS_ACTION_TYPES.GET_SINGLE_AINEWS.PENDING:
      return {
        ...state,
        getSingleAINewsPending: true,
      };
    case AINEWS_ACTION_TYPES.GET_SINGLE_AINEWS.FULFILLED:
      return {
        ...state,
        getSingleAINewsPending: false,
        singleNews: action.payload,
      };
    case AINEWS_ACTION_TYPES.GET_SINGLE_AINEWS.REJECTED:
      return {
        ...state,
        getSingleAINewsPending: false,
      };
    case AINEWS_ACTION_TYPES.UPDATE_AINEWS.PENDING:
      return {
        ...state,
        updateAINewsPending: true,
      };
    case AINEWS_ACTION_TYPES.UPDATE_AINEWS.FULFILLED:
      return {
        ...state,
        updateAINewsPending: false,
      };
    case AINEWS_ACTION_TYPES.UPDATE_AINEWS.REJECTED:
      return {
        ...state,
        updateAINewsPending: false,
      };
    case AINEWS_ACTION_TYPES.DELETE_AINEWS.PENDING:
      return {
        ...state,
        deleteAINewsPending: true,
      };
    case AINEWS_ACTION_TYPES.DELETE_AINEWS.FULFILLED:
      return {
        ...state,
        deleteAINewsPending: false,
      };
    case AINEWS_ACTION_TYPES.DELETE_AINEWS.REJECTED:
      return {
        ...state,
        deleteAINewsPending: false,
      };
    case AINEWS_ACTION_TYPES.GET_AISTORIES.PENDING:
      return {
        ...state,
        getAIStoriesPending: true,
      };
    case AINEWS_ACTION_TYPES.GET_AISTORIES.FULFILLED: {
      return {
        ...state,
        getAIStoriesPending: false,
        AIStories: action.payload,
        AIStoriesResults: action.payload.results,
      };
    }
    case AINEWS_ACTION_TYPES.GET_AISTORIES.REJECTED:
      return {
        ...state,
        getAIStoriesPending: false,
      };

    case AINEWS_ACTION_TYPES.GET_AILINKS.PENDING:
      return {
        ...state,
        getAILinksPending: true,
      };
    case AINEWS_ACTION_TYPES.GET_AILINKS.FULFILLED: {
      return {
        ...state,
        getAILinksPending: false,
        AILinks: action.payload,
        AILinksResults: action.payload.results,
      };
    }
    case AINEWS_ACTION_TYPES.GET_AILINKS.REJECTED:
      return {
        ...state,
        getAILinksPending: false,
      };

    default:
      return state;
  }
};


import React from "react";
import styled, { keyframes } from "styled-components";
import { SkeletonText } from "./Text";
import { STYLES_CONSTANTS } from 'styles/constants/constants';

const SAnimation = keyframes`
  0% {opacity: .5;}
	50% {opacity: 1;}
	100% {opacity: .5;}
`;

const SCardSkeleton = styled.div`
  width: 100%;
  height: 330px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  animation: ${SAnimation} 3s infinite;
  @media only screen and (min-width: 1920px) {
      padding: 0;
  }
`;

const SCardSkeletonWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 230px;
  margin-bottom: 10px;
  background-color: rgb(243 243 243 / 95%);
`;

const SNewsDescription = styled.div`
    width: 90%;
    padding: 8px 15px;
    position: absolute;
    bottom: 20px;
    left: 0;
    color: ${STYLES_CONSTANTS.COLORS.CHARCOAL_GREY};
`;

export const CardSkeleton = () => {
  return (
    <SCardSkeleton>
      <SCardSkeletonWrapper>
        <SNewsDescription>
          <SkeletonText height="15px" />
          <SkeletonText height="10px" marginBottom="10px" />
          <SkeletonText height="10px" width="50%" marginBottom="10px" />
          <SkeletonText height="10px" width="70%" marginBottom="10px" />
        </SNewsDescription>
      </SCardSkeletonWrapper>
      <SkeletonText height="10px" marginBottom="10px" />
      <SkeletonText height="10px" width="40%" marginBottom="10px" />
    </SCardSkeleton>
  )
};
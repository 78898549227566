import { createAsyncActionConstants } from 'util/util';

export const NOTIFICATIONS_ACTION_TYPES = {
    GET_NOTIFICATIONS: createAsyncActionConstants('GET_NOTIFICATIONS'),
    GET_MY_NOTIFICATIONS: createAsyncActionConstants('GET_MY_NOTIFICATIONS'),
    GET_NOTIFICATION: createAsyncActionConstants('GET_NOTIFICATION'),
    CREATE_NOTIFICATION: createAsyncActionConstants('CREATE_NOTIFICATION'),
    UPDATE_NOTIFICATION: createAsyncActionConstants('UPDATE_NOTIFICATION'),
    DELETE_NOTIFICATION: createAsyncActionConstants('DELETE_NOTIFICATION'),
    MARK_AS_READ_NOTIFICATION: createAsyncActionConstants('MARK_AS_READ_NOTIFICATION'),
    CREATE_NEWSLETTER: createAsyncActionConstants('CREATE_NEWSLETTER'),
};

import React from "react";
import { AuthenticationWrapper } from "../styled/authentication-styled";

const Error = (props) => {
  const onClose = () => {
    window.top.close();
  };

  return (
    <AuthenticationWrapper>
      <div className="container">
        <svg
          width="164"
          height="164"
          viewBox="0 0 164 164"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2392_11683)">
            <path
              d="M81.9998 0C36.7874 0 0 36.7841 0 81.9998C0 127.216 36.787 164 81.9998 164C127.216 164 164 127.216 164 81.9998C164 36.7841 127.216 0 81.9998 0ZM81.9998 152.786C42.969 152.786 11.214 121.032 11.214 81.9994C11.214 42.969 42.9686 11.214 81.9998 11.214C121.031 11.214 152.786 42.9686 152.786 81.9994C152.786 121.031 121.031 152.786 81.9998 152.786Z"
              fill="#E9E9E9"
            />
            <path
              d="M51.795 64.3882C60.7886 64.3882 68.1061 57.0699 68.1061 48.0738C68.1061 39.0786 60.7886 31.7603 51.795 31.7603C42.799 31.7603 35.4814 39.0786 35.4814 48.0738C35.4814 57.0699 42.799 64.3882 51.795 64.3882ZM51.795 42.9742C54.607 42.9742 56.893 45.2622 56.893 48.0738C56.893 50.8858 54.607 53.1742 51.795 53.1742C48.9834 53.1742 46.6946 50.8862 46.6946 48.0738C46.6946 45.2626 48.9834 42.9742 51.795 42.9742Z"
              fill="#E9E9E9"
            />
            <path
              d="M112.208 31.7603C103.212 31.7603 95.8936 39.0786 95.8936 48.0738C95.8936 57.0699 103.212 64.3882 112.208 64.3882C121.202 64.3882 128.519 57.0699 128.519 48.0738C128.519 39.0786 121.202 31.7603 112.208 31.7603ZM112.208 53.1742C109.396 53.1742 107.108 50.8862 107.108 48.0738C107.108 45.2622 109.396 42.9742 112.208 42.9742C115.02 42.9742 117.306 45.2622 117.306 48.0738C117.306 50.8862 115.019 53.1742 112.208 53.1742Z"
              fill="#E9E9E9"
            />
            <path
              d="M82 71.1719C56.3508 71.1719 35.4814 92.042 35.4814 117.691C35.4814 120.787 37.9911 123.298 41.0884 123.298C44.1849 123.298 46.6946 120.787 46.6946 117.691C46.6946 98.2232 62.5324 82.3854 82 82.3854C101.468 82.3854 117.306 98.2232 117.306 117.691C117.306 120.787 119.816 123.298 122.912 123.298C126.009 123.298 128.519 120.787 128.519 117.691C128.519 92.042 107.65 71.1719 82 71.1719Z"
              fill="#E9E9E9"
            />
          </g>
          <defs>
            <clipPath id="clip0_2392_11683">
              <rect width="164" height="164" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <h2 className="subtitle">Something went wrong!</h2>
        {props.msg && <h2 className="subtitle">{props.msg}</h2>}
        <p className="text">Please dismiss this window and try again later. </p>
        <button type="button" className="button" onClick={() => onClose()}>
          Try again
        </button>
      </div>
    </AuthenticationWrapper>
  );
};

export default Error;

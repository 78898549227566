/**
 * Created by Bartlomiej Rutkowski on 22.10.16.
 */
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { createStore, applyMiddleware } from "redux";
import { reducers } from "./reducers/reducers";
import { CONFIG } from "./config";

const createDevelopmentMiddleware = () => {
  const logger = createLogger();
  return applyMiddleware(thunk, logger);
};

const createProductionMiddleware = () => {
  return applyMiddleware(thunk);
};

export const testMiddleware = [thunk];

const middleware =
  CONFIG.ENVIRONMENT === "uat" ||
  CONFIG.ENVIRONMENT === "local" ||
  CONFIG.ENVIRONMENT === "development"
    ? createDevelopmentMiddleware()
    : createProductionMiddleware();

export const store = createStore(reducers, middleware);

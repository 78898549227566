import { TYPES } from "actionTypes/user";
import { FAVORITES_ACTION_TYPES } from "actionTypes/favorites";

const defaultState = {
  loginRequestPending: true,
  favoritesRequestPending: true,
  favoritesActionPending: false,
  favoriteRecommendationsPending: false,
  logout: false,
  isLogged: false,
  setCookiesPending: false,
  user: {},
  favoritesRecommendations: [],
  favorites: [],
  serverStatus: undefined,
};

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case TYPES.LOGIN.PENDING:
      return {
        ...state,
        loginRequestPending: true,
      };
    case TYPES.LOGIN.FULFILLED:
      return {
        ...state,
        user: action.user,
        isLogged: action.isLogged,
        loginRequestPending: false,
      };
    case TYPES.LOGOUT:
      return {
        ...state,
        isLogged: action.isLogged,
        logout: true,
      };
    case TYPES.SET_AUTH_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case TYPES.SET_COOKIES.PENDING:
      return {
        ...state,
        setCookiesPending: true,
      };
    case TYPES.SET_COOKIES.FULFILLED:
      return {
        ...state,
        setCookiesPending: false,
      };
    case TYPES.SET_COOKIES.REJECTED:
      return {
        ...state,
        setCookiesPending: false,
      };

    case FAVORITES_ACTION_TYPES.GET_FAVORITES.PENDING:
      return {
        ...state,
        favoritesRequestPending: true,
      };
    case FAVORITES_ACTION_TYPES.GET_FAVORITES.FULFILLED:
      return {
        ...state,
        favorites: action.payload,
        favoritesRequestPending: false,
      };
    case FAVORITES_ACTION_TYPES.GET_FAVORITES.REJECTED:
      return {
        ...state,
        favoritesRequestPending: false,
      };

    case FAVORITES_ACTION_TYPES.ADD_FAVORITES.PENDING:
      return {
        ...state,
        favoritesActionPending: action.payload.id,
      };
    case FAVORITES_ACTION_TYPES.GET_FAVORITES_RECOMMENDATIONS.PENDING:
      return {
        ...state,
        favoriteRecommendationsPending: true,
      };
    case FAVORITES_ACTION_TYPES.GET_FAVORITES_RECOMMENDATIONS.FULFILLED:
      return {
        ...state,
        favoriteRecommendationsPending: false,
        favoritesRecommendations: action.payload,
      };
    case FAVORITES_ACTION_TYPES.GET_FAVORITES_RECOMMENDATIONS.REJECTED:
      return {
        ...state,
        favoriteRecommendationsPending: false,
      };
    case FAVORITES_ACTION_TYPES.ADD_FAVORITES.FULFILLED:
      return {
        ...state,
        favoritesActionPending: false,
      };
    case FAVORITES_ACTION_TYPES.ADD_FAVORITES.REJECTED:
      return {
        ...state,
        favoritesActionPending: false,
      };

    case TYPES.SERVER_STATUS.FULFILLED:
      return {
        ...state,
        serverStatus: action.serverStatus,
      };

    default:
      return state;
  }
};
// ADD_FAVORITES

export const reducerLocal = (state, action) => {
    switch (action.type) {
        case 'storeMenuId':
            return {
                ...state,
                menuId: action.menuId,
            };
        case 'storeMenu':
            return {
                ...state,
                menu: action.menu
            };
        case 'storeLevel':
            return {
                ...state,
                level: action.level
            };
        default:
            throw new Error();
    }
};

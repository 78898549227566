export const CONFIG = {
  API_URL: "http://localhost:8081",
  REDIRECT_URL: "http://localhost:8000",
  SOCKET_URL: "http://localhost:8081/notifications",
  MOBILE_ALLOWED_PROTOCOL: "exp://",
  OKTA: {
    responseMode: "query",
    // issuer: "https://dev-60106666.okta.com/oauth2/default",
    // clientId: "0oafy527l0Hie0VCF5d7",
    issuer: "https://login-uat.cbre.com/oauth2/default",
    clientId: "0oa21r0a6r79FFOtl0h8",
    // issuer: "https://dev-60106666.okta.com/oauth2/default",
    // clientId: "0oafy527l0Hie0VCF5d7",
    redirectUri: "http://localhost:8000/",
    tokenManager: {
      storage: "localStorage",
    },
  },
  B2C: {
    authority:
      "https://cbreemeaext.b2clogin.com/tfp/cbreemeaext.onmicrosoft.com/B2C_1A_Zone_signup_signin",
    clientID: "5ba9b859-79b4-4249-9862-fe22e37b2a8b",
    graphScopes: ["https://cbreemeaext.onmicrosoft.com/cbrezone/dummy_zone"],
    knownAuthorities: ["cbreemeaext.b2clogin.com"],
  },
  GOOGLE_ANALYTICS_ID: "G-NS7BNNP8DR",
  ENVIRONMENT: "local",
};

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import styled from "styled-components";
import { STYLES_CONSTANTS } from "styles/constants/constants";
import { NoAccess } from "modules/noAccess/NoAccess";

const SBackground = styled.div`
  background: ${STYLES_CONSTANTS.COLORS.LIGHT_GREY3};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class _NoAccessView extends React.Component {

  static propTypes = {
    history: PropTypes.shape({
      block: PropTypes.func,
    }).isRequired,
    logoutCallback: PropTypes.func.isRequired,
  };

  state = {
    countdown: 5,
    logoutTimeout: null,
    countdownInterval: null,
  };

  componentDidMount() {
    this.props.history.block();
    this.state.logoutTimeout = setTimeout(() => {
      this.props.logoutCallback();
      clearInterval(this.state.countdownInterval);
    }, 6000);

    this.state.countdownInterval = setInterval(() => {
      this.setState({
        countdown: this.state.countdown === 0 ? 0 : this.state.countdown - 1,
      });
    }, 1000);
  }

  render() {
    return (
      <SBackground>
        <NoAccess countdown={this.state.countdown} />
      </SBackground>
    );
  }

}

const NoAccessView = withRouter(_NoAccessView);

export default NoAccessView;

export const CONFIG = {
  API_URL: "https://api.zone.cbre.es",
  REDIRECT_URL: "https://app.zone.cbre.es",
  SOCKET_URL: "https://api.zone.cbre.es/notifications",
  MOBILE_ALLOWED_PROTOCOL: "zone://",
  OKTA: {
    responseMode: "query",
    issuer: "https://login-uat.cbre.com/oauth2/default",
    clientId: "0oa21r0a6r79FFOtl0h8",
    redirectUri: "https://app.zone.cbre.es/",
    tokenManager: {
      storage: "localStorage",
    },
  },
  B2C: {
    authority:
      "https://cbreconnect.b2clogin.com/tfp/cbreconnect.onmicrosoft.com/b2c_1a_zone_signup_signin",
    clientID: "c1fe0d04-aff9-477a-a029-988b968b0c12",
    graphScopes: ["https://cbreconnect.onmicrosoft.com/cbrezone/dummy_zone"],
    knownAuthorities: ["cbreconnect.b2clogin.com"],
  },
  GOOGLE_ANALYTICS_ID: "G-8FQX16FP0G",
  ENVIRONMENT: "production",
};

import { isTableauUrl, sendFormData } from "util/util";

import { API_URLS } from "constants/apiUrl";
import { FILES_ACTION_TYPES } from "actionTypes/files";
import axios from "axios";

export const downloadFileExcel = (id) => async (dispatch) => {
  // dispatch({
  //   type: FILES_ACTION_TYPES.DOWNLOAD_FILES.PENDING,
  // });
  try {
    const response = await axios.post(
      `${API_URLS.GET_DASHBOARD}/${id}/excel`,
      {},
      {
        responseType: "blob",
      }
    );
    // dispatch({
    //   type: FILES_ACTION_TYPES.DOWNLOAD_FILES.FULFILLED,
    //   payload: response.data,
    // });
    return response;
  } catch (error) {
    // dispatch({
    //   type: FILES_ACTION_TYPES.DOWNLOAD_FILES.REJECTED,
    //   payload: error,
    // });
    throw error;
  }
};

export const getFilesRoot = () => async (dispatch) => {
  dispatch({
    type: FILES_ACTION_TYPES.GET_FILES_ROOT.PENDING,
  });

  try {
    try {
      const isAdmin = localStorage.getItem("user") !== "Business";
      if (!isAdmin) {
        let savedRoots = localStorage.getItem("rootFiles");
        if (savedRoots) {
          savedRoots = JSON.parse(savedRoots);

          dispatch({
            type: FILES_ACTION_TYPES.GET_FILES_ROOT.FULFILLED,
            filesRoot: savedRoots,
          });
          return savedRoots;
        }
      }
    } catch (e) {}
    const response = await axios.get(API_URLS.GET_FILES_ROOT);
    localStorage.setItem("rootFiles", JSON.stringify(response.data));
    dispatch({
      type: FILES_ACTION_TYPES.GET_FILES_ROOT.FULFILLED,
      filesRoot: response.data,
    });
    return response;
  } catch (error) {
    dispatch({
      type: FILES_ACTION_TYPES.GET_FILES_ROOT.REJECTED,
      payload: error,
    });
    throw error;
  }
};
export const getObject = async (id) => {
  const result = await axios.get(`${API_URLS.GET_OBJECT}/${id}`);

  if (result.data) {
    return result.data;
  }
};
export const createDocument = (body) => async (dispatch) => {
  dispatch({
    type: FILES_ACTION_TYPES.CREATE_DOCUMENT.PENDING,
  });
  const formData = sendFormData(body, ["thumbnail", "object"]);
  try {
    const response = await axios.post(API_URLS.CREATE_DOCUMENT, formData);
    dispatch({
      type: FILES_ACTION_TYPES.CREATE_DOCUMENT.FULFILLED,
    });
    return response;
  } catch (error) {
    dispatch({
      type: FILES_ACTION_TYPES.CREATE_DOCUMENT.REJECTED,
      payload: error,
    });
    throw error;
  }
};

export const createURLDashboard = (body) => async (dispatch) => {
  dispatch({
    type: FILES_ACTION_TYPES.CREATE_URL_DASHBOARD.PENDING,
  });
  try {
    if (isTableauUrl(body.url)) {
      const response = await axios.post(API_URLS.CREATE_URL_DASHBOARD, body);
      dispatch({
        type: FILES_ACTION_TYPES.CREATE_URL_DASHBOARD.FULFILLED,
        urlDashboard: response.data.url,
      });
      return response;
    }
    dispatch({
      type: FILES_ACTION_TYPES.CREATE_URL_DASHBOARD.FULFILLED,
      urlDashboard: body.url,
    });
  } catch (error) {
    dispatch({
      type: FILES_ACTION_TYPES.GET_FILES_ROOT.REJECTED,
    });
    throw error;
  }
};

export const searchFiles = (id, params) => async (dispatch) => {
  dispatch({
    type: FILES_ACTION_TYPES.SEARCH_FILES.PENDING,
  });

  try {
    const { type, ...axiosParams } = params;
    let response;
    if (type == "reports" && axiosParams.limit == 8 && axiosParams.name == "") {
      response = await axios.get(
        `${API_URLS.GET_FOLDER}/${type == "reports" ? "library/" : ""}${id}`,
        {
          params: { ...axiosParams, sortByCustomDate: true },
        }
      );
    } else {
      response = await axios.get(`${API_URLS.SEARCH_FILES}/${id}`, {
        params: axiosParams,
      });
    }
    dispatch({
      type: FILES_ACTION_TYPES.SEARCH_FILES.FULFILLED,
      payload: {
        type,
        response:
          type == "reports" && axiosParams.limit == 8 && axiosParams.name == ""
            ? { results: response.data.children.slice(0, 8) }
            : response.data,
      },
    });
    return response;
  } catch (error) {
    dispatch({
      type: FILES_ACTION_TYPES.SEARCH_FILES.REJECTED,
    });
    throw error;
  }
};

export const getFile = (id) => async (dispatch) => {
  dispatch({
    type: FILES_ACTION_TYPES.GET_FILE.PENDING,
  });

  try {
    const response = await axios.get(`${API_URLS.GET_FILE}/${id}`);
    dispatch({
      type: FILES_ACTION_TYPES.GET_FILE.FULFILLED,
      payload: response.data,
    });
    return response;
  } catch (error) {
    dispatch({
      type: FILES_ACTION_TYPES.GET_FILE.REJECTED,
    });
    throw error;
  }
};
export const getReports = (params) => async (dispatch) => {
  dispatch({
    type: FILES_ACTION_TYPES.GET_FILES.PENDING,
  });
  try {
    const response = await axios.get(API_URLS.GET_FILES, { params });
    dispatch({
      type: FILES_ACTION_TYPES.GET_FILES.FULFILLED,
      payload: { ...response.data, reports: true },
    });
    return response;
  } catch (error) {
    dispatch({
      type: FILES_ACTION_TYPES.GET_FILES.REJECTED,
    });
    throw error;
  }
};
export const getFiles = (params) => async (dispatch) => {
  dispatch({
    type: FILES_ACTION_TYPES.GET_FILES.PENDING,
  });
  try {
    const response = await axios.get(API_URLS.GET_FILES, { params });
    dispatch({
      type: FILES_ACTION_TYPES.GET_FILES.FULFILLED,
      payload: response.data,
    });
    return response;
  } catch (error) {
    dispatch({
      type: FILES_ACTION_TYPES.GET_FILES.REJECTED,
    });
    throw error;
  }
};

export const updateFile = (id, body) => async (dispatch) => {
  dispatch({
    type: FILES_ACTION_TYPES.UPDATE_FILE.PENDING,
  });
  const formData = sendFormData(body, ["thumbnail", "object"]);
  try {
    const response = await axios.patch(
      `${API_URLS.UPDATE_FILE}/${id}`,
      formData
    );
    dispatch({
      type: FILES_ACTION_TYPES.UPDATE_FILE.FULFILLED,
    });
    return response;
  } catch (error) {
    dispatch({
      type: FILES_ACTION_TYPES.UPDATE_FILE.REJECTED,
      payload: error,
    });
    throw error;
  }
};

export const deleteFile = (id) => async (dispatch) => {
  dispatch({
    type: FILES_ACTION_TYPES.DELETE_FILE.PENDING,
  });

  try {
    const response = await axios.delete(`${API_URLS.DELETE_FILE}/${id}`);
    dispatch({
      type: FILES_ACTION_TYPES.DELETE_FILE.FULFILLED,
    });
    return response;
  } catch (error) {
    dispatch({
      type: FILES_ACTION_TYPES.DELETE_FILE.REJECTED,
    });
    throw error;
  }
};

export const getFolder =
  (id, page, limit, groups, type) => async (dispatch) => {
    dispatch({
      type: FILES_ACTION_TYPES.GET_FOLDER.PENDING,
    });
    try {
      const response = await axios.get(
        `${API_URLS.GET_FOLDER}${
          type && type == "library" ? `/library/` : "/"
        }${id}`,
        {
          params: { page, limit, groups, sortByCustomDate: true },
        }
      );
      dispatch({
        type: FILES_ACTION_TYPES.GET_FOLDER.FULFILLED,
        folder: response.data,
        files: response.data.children,
      });
      return response;
    } catch (error) {
      dispatch({
        type: FILES_ACTION_TYPES.GET_FOLDER.REJECTED,
      });
      throw error;
    }
  };

export const getDEMOFolder = (id) => async (dispatch) => {
  dispatch({
    type: FILES_ACTION_TYPES.GET_DEMO_FOLDER.PENDING,
  });

  try {
    const response = await axios.get(`${API_URLS.GET_FOLDER}/${id}/demo`);
    dispatch({
      type: FILES_ACTION_TYPES.GET_DEMO_FOLDER.FULFILLED,
      folder: response.data,
      files: response.data.children,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: FILES_ACTION_TYPES.GET_DEMO_FOLDER.REJECTED,
    });
    throw error;
  }
};

export const getDashboard = (id) => async (dispatch) => {
  dispatch({
    type: FILES_ACTION_TYPES.GET_SINGLE_DASHBOARD.PENDING,
  });
  try {
    const response = await axios.get(`${API_URLS.GET_SINGLE_DASHBOARD}/${id}`);
    dispatch({
      type: FILES_ACTION_TYPES.GET_SINGLE_DASHBOARD.FULFILLED,
      dashboard: response.data,
    });
    const url = response.data.url;
    await dispatch(createURLDashboard({ url }));
    return response;
  } catch (error) {
    dispatch({
      type: FILES_ACTION_TYPES.GET_SINGLE_DASHBOARD.REJECTED,
      payload: error,
    });
    throw error;
  }
};

export const updateModuleInfo = (id, body) => async (dispatch) => {
  dispatch({
    type: FILES_ACTION_TYPES.UPDATE_MODULE.PENDING,
  });
  try {
    const response = await axios.patch(`${API_URLS.UPDATE_MODULE}/${id}`, body);
    dispatch({
      type: FILES_ACTION_TYPES.UPDATE_MODULE.FULFILLED,
      module: response.data,
    });
    return response;
  } catch (error) {
    dispatch({
      type: FILES_ACTION_TYPES.UPDATE_MODULE.REJECTED,
      payload: error,
    });
    throw error;
  }
};

export const createModule = (module) => {
  return async (dispatch) => {
    dispatch({
      type: FILES_ACTION_TYPES.CREATE_ROOT_MODULE.PENDING,
    });
    const formData = sendFormData(module, ["rootModuleImage"]);
    try {
      const response = await axios.post(API_URLS.CREATE_ROOT_MODULE, formData);
      dispatch({
        type: FILES_ACTION_TYPES.CREATE_ROOT_MODULE.FULFILLED,
      });
      return response;
    } catch (e) {
      dispatch({
        type: FILES_ACTION_TYPES.CREATE_ROOT_MODULE.REJECTED,
      });
      throw e;
    }
  };
};

export const updateModule = (id, module) => {
  return async (dispatch) => {
    dispatch({
      type: FILES_ACTION_TYPES.UPDATE_ROOT_MODULE.PENDING,
    });
    const formData = sendFormData(module, ["rootModuleImage"]);
    try {
      const response = await axios.patch(
        `${API_URLS.UPDATE_ROOT_MODULE}/${id}`,
        formData
      );
      dispatch({
        type: FILES_ACTION_TYPES.UPDATE_ROOT_MODULE.FULFILLED,
      });
      return response;
    } catch (e) {
      dispatch({
        type: FILES_ACTION_TYPES.UPDATE_ROOT_MODULE.REJECTED,
      });
      throw e;
    }
  };
};

export const deleteModule = (id) => {
  return async (dispatch) => {
    dispatch({
      type: FILES_ACTION_TYPES.DELETE_ROOT_MODULE.PENDING,
    });
    try {
      const response = await axios.delete(
        `${API_URLS.DELETE_ROOT_MODULE}/${id}`
      );
      dispatch({
        type: FILES_ACTION_TYPES.DELETE_ROOT_MODULE.FULFILLED,
      });
      return response;
    } catch (e) {
      dispatch({
        type: FILES_ACTION_TYPES.DELETE_ROOT_MODULE.REJECTED,
      });
      throw e;
    }
  };
};

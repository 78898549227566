import { createAsyncActionConstants } from "util/util";

export const FILES_ACTION_TYPES = {
  GET_FILES_ROOT: createAsyncActionConstants("GET_FILES_ROOT"),
  CREATE_URL_DASHBOARD: createAsyncActionConstants("CREATE_URL_DASHBOARD"),
  CREATE_DOCUMENT: createAsyncActionConstants("CREATE_DOCUMENT"),
  GET_FOLDER: createAsyncActionConstants("GET_FOLDER"),
  GET_DEMO_FOLDER: createAsyncActionConstants("GET_DEMO_FOLDER"),
  GET_FILE: createAsyncActionConstants("GET_FILE"),
  GET_FILES: createAsyncActionConstants("GET_FILES"),
  SEARCH_FILES: createAsyncActionConstants("SEARCH_FILES"),
  UPDATE_FILE: createAsyncActionConstants("UPDATE_FILE"),
  DELETE_FILE: createAsyncActionConstants("DELETE_FILE"),
  GET_SINGLE_DASHBOARD: createAsyncActionConstants("GET_SINGLE_DASHBOARD"),
  UPDATE_MODULE: createAsyncActionConstants("UPDATE_MODULE"),
  CREATE_ROOT_MODULE: createAsyncActionConstants("CREATE_ROOT_MODULE"),
  UPDATE_ROOT_MODULE: createAsyncActionConstants("UPDATE_ROOT_MODULE"),
  DELETE_ROOT_MODULE: createAsyncActionConstants("DELETE_ROOT_MODULE"),
};

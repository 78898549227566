import { createAsyncActionConstants } from 'util/util';

export const FORECASTING_TYPES = {
    CHOOSE_GENERATE: 'CHOOSE_GENERATE',
    CLEAR_DATA_LAKE_STATE: 'CLEAR_DATA_LAKE_STATE',
    GET_FORECASTING_DOCUMENTS: createAsyncActionConstants('GET_FORECASTING_DOCUMENTS'),
    UPDATE_FORECASTING_DOCUMENTS: createAsyncActionConstants('UPDATE_FORECASTING_DOCUMENTS'),
    GET_DATA_LAKE_SECTORS: createAsyncActionConstants('GET_DATA_LAKE_SECTORS'),
    GET_DATA_LAKE_SCENARIOS: createAsyncActionConstants('GET_DATA_LAKE_SCENARIOS'),
    GET_DATA_LAKE_GROUPS: createAsyncActionConstants('GET_DATA_LAKE_GROUPS'),
    GET_DATA_LAKE_LOCATION_LAYERS: createAsyncActionConstants('GET_DATA_LAKE_LOCATION_LAYERS'),
    GET_DATA_LAKE_VALUES: createAsyncActionConstants('GET_DATA_LAKE_VALUES'),
    GET_MEASUREMENTS: createAsyncActionConstants('GET_MEASUREMENTS'),
    GET_FREQUENCIES: createAsyncActionConstants('GET_FREQUENCIES'),
    GET_TIME_SPAN: createAsyncActionConstants('GET_TIME_SPAN'),
    GET_MINI_TABLE_KPI_VALUES: createAsyncActionConstants('GET_MINI_TABLE_KPI_VALUES'),
    GET_LOCATIONS_TREE: createAsyncActionConstants('GET_LOCATIONS_TREE'),
    GET_DATA_LAKE_VALUES_TABLE: createAsyncActionConstants('GET_DATA_LAKE_VALUES_TABLE'),
    DOWNLOAD_EXCEL_TABLE: createAsyncActionConstants('DOWNLOAD_EXCEL_TABLE'),
    GET_TABLE_ROW_COUNT: createAsyncActionConstants('GET_TABLE_ROW_COUNT'),
    SET_GRAPH_DATA: 'SET_GRAPH_DATA',
    CLEAR_GRAPH_DATA: 'CLEAR_GRAPH_DATA',
    SET_MAP_DATA: 'SET_MAP_DATA',
    CLEAR_MAP_DATA: 'CLEAR_MAP_DATA',
    RESET_DATA_CHANGE_SECTOR: 'RESET_DATA_CHANGE_SECTOR',
};

import { NOTIFICATIONS_ACTION_TYPES } from 'actionTypes/notifications';

const defaultState = {
    getNotificationsPending: false,
    getMyNotificationsPending: false,
    getNotificationPending: false,
    createNotificationPending: false,
    updateNotificationPending: false,
    deleteNotificationPending: false,
    markAsReadNotificationPending: false,
    createNewsletterPending: false,
    notifications: {
        limit: 0,
        page: 0,
        count: 0,
        results: []
    },
    myNotifications: {
        limit: 0,
        page: 0,
        count: 0,
        results: []
    },
    notification: {
        tags: [],
        groups: [],
    }
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case NOTIFICATIONS_ACTION_TYPES.GET_NOTIFICATIONS.PENDING:
            return {
                ...state,
                getNotificationsPending: true,
            };
        case NOTIFICATIONS_ACTION_TYPES.GET_NOTIFICATIONS.FULFILLED:
            return {
                ...state,
                getNotificationsPending: false,
                notifications: action.payload
            };
        case NOTIFICATIONS_ACTION_TYPES.GET_NOTIFICATIONS.REJECTED:
            return {
                ...state,
                getNotificationsPending: false,
            };
        case NOTIFICATIONS_ACTION_TYPES.GET_MY_NOTIFICATIONS.PENDING:
            return {
                ...state,
                getMyNotificationsPending: true,
            };
        case NOTIFICATIONS_ACTION_TYPES.GET_MY_NOTIFICATIONS.FULFILLED:
            return {
                ...state,
                getMyNotificationsPending: false,
                myNotifications: action.payload
            };
        case NOTIFICATIONS_ACTION_TYPES.GET_MY_NOTIFICATIONS.REJECTED:
            return {
                ...state,
                getMyNotificationsPending: false,
            };
        case NOTIFICATIONS_ACTION_TYPES.GET_NOTIFICATION.PENDING:
            return {
                ...state,
                getNotificationPending: true,
            };
        case NOTIFICATIONS_ACTION_TYPES.GET_NOTIFICATION.FULFILLED:
            return {
                ...state,
                getNotificationPending: false,
                notification: action.payload
            };
        case NOTIFICATIONS_ACTION_TYPES.GET_NOTIFICATION.REJECTED:
            return {
                ...state,
                getNotificationPending: false,
            };
        case NOTIFICATIONS_ACTION_TYPES.CREATE_NOTIFICATION.PENDING:
            return {
                ...state,
                createNotificationPending: true,
            };
        case NOTIFICATIONS_ACTION_TYPES.CREATE_NOTIFICATION.FULFILLED:
            return {
                ...state,
                createNotificationPending: false,
            };
        case NOTIFICATIONS_ACTION_TYPES.CREATE_NOTIFICATION.REJECTED:
            return {
                ...state,
                createNotificationPending: false,
            };
        case NOTIFICATIONS_ACTION_TYPES.UPDATE_NOTIFICATION.PENDING:
            return {
                ...state,
                updateNotificationPending: true,
            };
        case NOTIFICATIONS_ACTION_TYPES.UPDATE_NOTIFICATION.FULFILLED:
            return {
                ...state,
                updateNotificationPending: false,
            };
        case NOTIFICATIONS_ACTION_TYPES.UPDATE_NOTIFICATION.REJECTED:
            return {
                ...state,
                updateNotificationPending: false,
            };
        case NOTIFICATIONS_ACTION_TYPES.DELETE_NOTIFICATION.PENDING:
            return {
                ...state,
                deleteNotificationPending: true,
            };
        case NOTIFICATIONS_ACTION_TYPES.DELETE_NOTIFICATION.FULFILLED:
            return {
                ...state,
                deleteNotificationPending: false,
            };
        case NOTIFICATIONS_ACTION_TYPES.DELETE_NOTIFICATION.REJECTED:
            return {
                ...state,
                deleteNotificationPending: false,
            };
        case NOTIFICATIONS_ACTION_TYPES.MARK_AS_READ_NOTIFICATION.PENDING:
            return {
                ...state,
                markAsReadNotificationPending: true,
            };
        case NOTIFICATIONS_ACTION_TYPES.MARK_AS_READ_NOTIFICATION.FULFILLED:
            return {
                ...state,
                markAsReadNotificationPending: false,
            };
        case NOTIFICATIONS_ACTION_TYPES.MARK_AS_READ_NOTIFICATION.REJECTED:
            return {
                ...state,
                markAsReadNotificationPending: false,
            };
        case NOTIFICATIONS_ACTION_TYPES.CREATE_NEWSLETTER.PENDING:
            return {
                ...state,
                createNewsletterPending: true,
            };
        case NOTIFICATIONS_ACTION_TYPES.CREATE_NEWSLETTER.FULFILLED:
            return {
                ...state,
                createNewsletterPending: false,
            };
        case NOTIFICATIONS_ACTION_TYPES.CREATE_NEWSLETTER.REJECTED:
            return {
                ...state,
                createNewsletterPending: false,
            };
        default:
            return state;
    }
};

import { TYPES } from "actionTypes/users";

const defaultState = {
  users: {
    results: [],
    page: 1,
    limit: 20,
    count: 0,
  },
  usersApp: {
    results: [],
    page: 1,
    limit: 20,
    count: 0,
  },
  usersSearch: {
    results: [],
    page: 1,
    limit: 20,
    count: 0,
  },
  currentUser: {
    email: [],
    cbreContacts: [],
    modules: [],
    groups: [],
  },
  userInfo: {
    email: [],
    cbreContacts: [],
    modules: [],
    groups: [],
  },
  cbreUsers: [],
  getUserPending: false,
  getUsersPending: false,
  getAppUsersPending: false,
  getCBREUsersPending: false,
  addContactPending: false,
  sendSupportMailPending: false,
  deleteUserPending: false,
  setUserAsSbuPending: false,
  assignSbuGroup: false,
};

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case TYPES.UPDATE_USER.PENDING:
      return {
        ...state,
        getUserPending: true,
      };
    case TYPES.UPDATE_USER.FULFILLED:
      return {
        ...state,
        getUserPending: false,
      };
    case TYPES.UPDATE_USER.REJECTED:
      return {
        ...state,
        getUserPending: false,
      };
    case TYPES.GET_USERS.PENDING:
      return {
        ...state,
        getUsersPending: true,
      };
    case TYPES.GET_USERS.FULFILLED:
      return {
        ...state,
        users: action.payload,
        getUsersPending: false,
      };
    case TYPES.GET_APP_USERS.PENDING:
      return {
        ...state,
        getAppUsersPending: true,
      };
    case TYPES.GET_APP_USERS.FULFILLED:
      return {
        ...state,
        usersApp: action.payload,
        getAppUsersPending: false,
      };
    case TYPES.GET_USERS_SEARCH.PENDING:
      return {
        ...state,
        getUsersPending: true,
      };
    case TYPES.GET_USERS_SEARCH.FULFILLED:
      return {
        ...state,
        usersSearch: action.payload,
        getUsersPending: false,
      };
    case TYPES.GET_SINGLE_USER.PENDING:
      return {
        ...state,
        getUserPending: true,
      };
    case TYPES.GET_SINGLE_USER.FULFILLED:
      if (action.payload) {
        return {
          ...state,
          userInfo: action.payload,
          getUserPending: false,
        };
      } else {
        return {
          ...state,

          getUserPending: false,
        };
      }
    case TYPES.GET_SINGLE_USER.REJECTED:
      return {
        ...state,

        getUserPending: false,
      };
    case TYPES.ADD_CONTACT.PENDING:
      return {
        ...state,
        addContactPending: true,
      };
    case TYPES.ADD_CONTACT.FULFILLED:
      return {
        ...state,
        addContactPending: false,
      };
    case TYPES.GET_CBRE_USERS.PENDING:
      return {
        ...state,
        getCBREUsersPending: true,
      };
    case TYPES.GET_CBRE_USERS.FULFILLED:
      return {
        ...state,
        cbreUsers: action.cbreUsers,
        getCBREUsersPending: false,
      };
    case TYPES.GET_MY_PROFILE.PENDING:
      return {
        ...state,
        getUserPending: true,
      };
    case TYPES.GET_MY_PROFILE.FULFILLED:
      return {
        ...state,
        currentUser: action.payload,
        getUserPending: false,
      };
    case TYPES.SEND_SUPPORT_MAIL.PENDING:
      return {
        ...state,
        sendSupportMailPending: true,
      };
    case TYPES.SEND_SUPPORT_MAIL.FULFILLED:
      return {
        ...state,
        sendSupportMailPending: false,
      };
    case TYPES.SEND_SUPPORT_MAIL.REJECTED:
      return {
        ...state,
        sendSupportMailPending: false,
      };

    case TYPES.DELETE_USER.PENDING:
      return {
        ...state,
        deleteUserPending: true,
      };
    case TYPES.DELETE_USER.FULFILLED:
      return {
        ...state,
        deleteUserPending: false,
      };
    case TYPES.DELETE_USER.REJECTED:
      return {
        ...state,
        deleteUserPending: false,
      };
    case TYPES.SET_USER_AS_SBU.PENDING:
      return {
        ...state,
        setUserAsSbuPending: true,
      };
    case TYPES.SET_USER_AS_SBU.FULFILLED:
      return {
        ...state,
        setUserAsSbuPending: false,
      };
    case TYPES.SET_USER_AS_SBU.REJECTED:
      return {
        ...state,
        setUserAsSbuPending: false,
      };
    case TYPES.ADD_SBU_GROUP.PENDING:
      return {
        ...state,
        assignSbuGroup: true,
      };
    case TYPES.ADD_SBU_GROUP.FULFILLED:
      return {
        ...state,
        assignSbuGroup: false,
      };
    case TYPES.ADD_SBU_GROUP.REJECTED:
      return {
        ...state,
        assignSbuGroup: false,
      };
    default:
      return state;
  }
};

import { TYPES } from 'actionTypes/socket';

const defaultState = {
    socket: {}
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case TYPES.SET_SOCKET:
            return {
                ...state,
                socket: action.payload
            };
        default:
            return state;
    }
};
import { ACTION_TYPES } from 'actionTypes/favQueries';

const defaultState = {
    getFavoriteQueryPending: false,
    saveFavoriteQueryPending: false,
    getFavoriteQueriesPending: false,
    deleteFavoriteQueryPending: false,
    queries: {
        favoriteQueries: []
    },
    query: {}
};


export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_FAVORITE_QUERIES.PENDING:
            return {
                ...state,
                getFavoriteQueriesPending: true,
            };
        case ACTION_TYPES.GET_FAVORITE_QUERIES.FULFILLED:
            return {
                ...state,
                queries: action.payload,
                getFavoriteQueriesPending: false,
            };
        case ACTION_TYPES.GET_FAVORITE_QUERIES.REJECTED:
            return {
                ...state,
                getFavoriteQueriesPending: false,
            };

        case ACTION_TYPES.SAVE_FAVORITE_QUERY.PENDING:
            return {
                ...state,
                saveFavoriteQueryPending: true,
            };
        case ACTION_TYPES.SAVE_FAVORITE_QUERY.FULFILLED:
            return {
                ...state,
                saveFavoriteQueryPending: false,
            };
        case ACTION_TYPES.SAVE_FAVORITE_QUERY.REJECTED:
            return {
                ...state,
                saveFavoriteQueryPending: false,
            };

        case ACTION_TYPES.GET_FAVORITE_QUERY.PENDING:
            return {
                ...state,
                getFavoriteQueryPending: true,
            };
        case ACTION_TYPES.GET_FAVORITE_QUERY.FULFILLED:
            return {
                ...state,
                query: action.payload,
                getFavoriteQueryPending: false,
            };
        case ACTION_TYPES.GET_FAVORITE_QUERY.REJECTED:
            return {
                ...state,
                getFavoriteQueryPending: false,
            };

        case ACTION_TYPES.DELETE_FAVORITE_QUERY.PENDING:
            return {
                ...state,
                deleteFavoriteQueryPending: true,
            };
        case ACTION_TYPES.DELETE_FAVORITE_QUERY.FULFILLED:
            return {
                ...state,
                deleteFavoriteQueryPending: false,
            };
        case ACTION_TYPES.DELETE_FAVORITE_QUERY.REJECTED:
            return {
                ...state,
                deleteFavoriteQueryPending: false,
            };

        default:
            return state;
    }
};
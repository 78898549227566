import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { STYLES_CONSTANTS } from 'styles/constants/constants';

ReactModal.setAppElement('#main');

export class Modal extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        isOpen: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired,
        overrideStyles: PropTypes.object,
    };

    static defaultProps = {
        overrideStyles: {
            content: {},
            overlay: {}
        }
    };
    render() {
        const style = (styles) => ({
            overlay: {
                position: 'fixed',
                zIndex: STYLES_CONSTANTS.ZINDEX.ZINDEX_MODAL,
                top: 0,
                left: 0,
                width: '100%',
                backgroundColor: STYLES_CONSTANTS.COLORS.BLACK_40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...styles.overlay,
            },
            content: {
                top: styles.content.top ? styles.content.top : 'auto',
                left: styles.content.left ? styles.content.left : 'auto',
                right: 'auto',
                bottom: 'auto',
                background: STYLES_CONSTANTS.COLORS.WHITE,
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                outline: 'none',
                display: 'flex',
                flexDirection: 'column',
                alignItems: styles.content.alignItems ? styles.content.alignItems : 'center',
                justifyContent: styles.content.justifyContent ? styles.content.justifyContent : 'space-around',
                minHeight: styles.content.minHeight ? styles.content.minHeight : '270px',
                width: styles.content.width ? styles.content.width : '545px',
                padding: styles.content.padding ? styles.content.padding : '70px',
                maxHeight: styles.content.maxHeight ? styles.content.maxHeight : '90vh',
                transform: styles.content.transform ? styles.content.transform : 'none'
            }
        });

        return (
            <ReactModal
                {...this.props}
                isOpen={this.props.isOpen}
                style={style(this.props.overrideStyles)}
                shouldCloseOnEsc
                shouldCloseOnOverlayClick
                ariaHideApp
                shouldReturnFocusAfterClose
                onRequestClose={this.props.close}
            >
                {this.props.children}
            </ReactModal>
        );
    }
}


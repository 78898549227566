/**
 * Created by kurtwagner on 06.03.2018
 */
import { STYLES_CONSTANTS } from "styles/constants/constants";
import { fontFace } from "../util/utilities/styles";

export const GlobalStyles = `
    * {
${fontFace("Calibre-Light", "Calibre-Regular")};
${fontFace("Calibre-Medium", "Calibre-Medium")};
${fontFace("Financier", "Financier/FinancierDisplay-Regular")};
${fontFace("FinancierBold", "Financier/FinancierDisplay-Semibold")};
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;
        font-family: 'Calibre-Light', sans-serif !important;
    }
    html {
        height: 100%;
        #main {
            height: 100%;
        }
    }
    body {
        position: relative;
        height: 100%;
        width: 100%;
        color: ${STYLES_CONSTANTS.COLORS.BLACK};
        &.ReactModal__Body--open {
            overflow: hidden;
            
            &.modal-blocked-content {
                #main {
                    filter: blur(2px);
                }
            }
        }
        > iframe {
            position: fixed !important;
            top: 0 !important;
            left: 0 !important;
            display: none !important;
            z-index: -1 !important;
        }
    }
    a {
        text-decoration: none;
        color: ${STYLES_CONSTANTS.COLORS.BLACK};
    }
    h1 { 
        font-size: 22px;
        line-height: 1.2;
        margin-top: 0;
    }
    h1, h2, h3, h4, h5, h6 {
        font-weight: normal;
    }
    button {
        background: transparent;
        border: 0;
        cursor: pointer;
    }
    input {
        -webkit-appearance: none;
    }
    .toast-message_ss {
        background:  #012a2d;
        color: #fff;
        padding: 30px 20px;
    }
    .toast-progress_ss {
        background: #17e88fa1;
      }
`;

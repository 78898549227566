import axios from 'axios';
import { API_URLS } from 'constants/apiUrl';
import { NOTIFICATIONS_ACTION_TYPES } from 'actionTypes/notifications';

export const getNotifications = (params) => async dispatch => {
    dispatch({
        type: NOTIFICATIONS_ACTION_TYPES.GET_NOTIFICATIONS.PENDING
    });
    try {
        const response = await axios.get(API_URLS.GET_NOTIFICATIONS, {
            params
        });
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.GET_NOTIFICATIONS.FULFILLED,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.GET_NOTIFICATIONS.REJECTED,
        });
        throw error;
    }
};

export const getMyNotifications = (params = { page: 1, limit: 10 }) => async dispatch => {
    dispatch({
        type: NOTIFICATIONS_ACTION_TYPES.GET_MY_NOTIFICATIONS.PENDING
    });
    try {
        const response = await axios.get(API_URLS.GET_MY_NOTIFICATIONS, {
            params
        });
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.GET_MY_NOTIFICATIONS.FULFILLED,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.GET_MY_NOTIFICATIONS.REJECTED,
        });
        throw error;
    }
};

export const getNotification = (id) => async dispatch => {
    dispatch({
        type: NOTIFICATIONS_ACTION_TYPES.GET_NOTIFICATION.PENDING
    });
    try {
        const response = await axios.get(`${API_URLS.GET_NOTIFICATION}/${id}`);
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.GET_NOTIFICATION.FULFILLED,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.GET_NOTIFICATION.REJECTED,
        });
        throw error;
    }
};

export const createNotification = (body) => async (dispatch) => {
    dispatch({
        type: NOTIFICATIONS_ACTION_TYPES.CREATE_NOTIFICATION.PENDING,
    });
    try {
        const response = await axios.post(API_URLS.CREATE_NOTIFICATION, body);
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.CREATE_NOTIFICATION.FULFILLED,
        });
        return response;
    } catch (error) {
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.CREATE_NOTIFICATION.REJECTED,
            payload: error,
        });
        throw error;
    }
};

export const updateNotification = (id, body) => async (dispatch) => {
    dispatch({
        type: NOTIFICATIONS_ACTION_TYPES.UPDATE_NOTIFICATION.PENDING,
    });
    try {
        const response = await axios.patch(`${API_URLS.UPDATE_NOTIFICATION}/${id}`, body);
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.UPDATE_NOTIFICATION.FULFILLED,
        });
        return response;
    } catch (error) {
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.UPDATE_NOTIFICATION.REJECTED,
            payload: error,
        });
        throw error;
    }
};

export const deleteNotification = (id) => async dispatch => {
    dispatch({
        type: NOTIFICATIONS_ACTION_TYPES.DELETE_NOTIFICATION.PENDING
    });

    try {
        const response = await axios.delete(`${API_URLS.DELETE_NOTIFICATION}/${id}`);
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.DELETE_NOTIFICATION.FULFILLED,
        });
        return response;
    } catch (error) {
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.DELETE_NOTIFICATION.REJECTED,
        });
        throw error;
    }
};

export const markAsReadNotification = (body) => async (dispatch) => {
    dispatch({
        type: NOTIFICATIONS_ACTION_TYPES.MARK_AS_READ_NOTIFICATION.PENDING,
    });
    try {
        const response = await axios.post(API_URLS.MARK_AS_READ_NOTIFICATION, body);
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.MARK_AS_READ_NOTIFICATION.FULFILLED,
        });
        return response;
    } catch (error) {
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.MARK_AS_READ_NOTIFICATION.REJECTED,
            payload: error,
        });
        throw error;
    }
};

export const createNewsletter = (body) => async (dispatch) => {
    dispatch({
        type: NOTIFICATIONS_ACTION_TYPES.CREATE_NEWSLETTER.PENDING,
    });
    try {
        const response = await axios.post(API_URLS.CREATE_NEWSLETTER, body);
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.CREATE_NEWSLETTER.FULFILLED,
        });
        return response;
    } catch (error) {
        dispatch({
            type: NOTIFICATIONS_ACTION_TYPES.CREATE_NEWSLETTER.REJECTED,
            payload: error,
        });
        throw error;
    }
};

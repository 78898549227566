/**
 * Created by kurtwagner on 13.04.2018
 */

export function fontFace(
  name,
  src,
  fontWeight = "normal",
  fontStyle = "normal"
) {
  const PATH_TO_FONTS_DIRECTORY = "./assets/fonts";
  return `
        @font-face{
            font-family: "${name}";
            src: url(${`${PATH_TO_FONTS_DIRECTORY}/${src}.eot`});
            src: url(${`${PATH_TO_FONTS_DIRECTORY}/${src}.otf`});
            src: url(${`${PATH_TO_FONTS_DIRECTORY}/${src}.eot`}?#iefix) format("embedded-opentype"),
                 url(${`${PATH_TO_FONTS_DIRECTORY}/${src}.ttf`}) format("truetype"),
                 url(${`${PATH_TO_FONTS_DIRECTORY}/${src}.svg`}#${name}) format("svg");

            font-style: ${fontStyle};
            font-weight: ${fontWeight};
        }
    `;
}

export const getPlaceholderColorStyles = (color) => `
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: ${color};
      text-transform: uppercase;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: ${color};
      text-transform: uppercase;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: ${color};
      text-transform: uppercase;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: ${color};
      text-transform: uppercase;
    }
`;

import { FORECASTING_TYPES } from 'actionTypes/forecasting';
import { GENERATE_DATA_TYPES } from 'constants/generateTypes';
import { PAGINATION } from 'modules/forecast-analytics/table/settings';

const defaultState = {
    getForecastingDocumentsPending: false,
    updateForecastingDocumentsPending: false,
    getMeasurementsPending: false,
    getFrequenciesPending: false,
    getTimeSpanPending: false,
    getLocationsTreePending: false,
    downloadExcelTablePending: false,
    getTableRowCountPending: false,
    locationsTree: [{
        tree: [{

        }, {

        }]
    }],
    documents: {
        aboutUs: '',
        methodology: '',
    },
    sectors: {
        sectors: [],
    },
    kpis: [],
    miniTableKpis: [],
    measurements: {
        measurements: [],
    },
    frequencies: {
        frequencies: [],
    },
    timeSpan: {},
    savedTreeData: [],
};

const defaultParamsState = {
    getDataLakeSectorsPending: false,
    getDataLakeScenariosPending: false,
    getDataLakeGroupsPending: false,
    getDataLakeKpiPending: false,
    getDataLakeLocationsPending: false,
    getMiniTableKpiValuesPending: false,
    getDataLakeValuesTablePending: false,
    generateDataName: GENERATE_DATA_TYPES.INITIAL_PANEL,
    previousDataName: GENERATE_DATA_TYPES.INITIAL_PANEL,
    scenarios: {
        scenarios: [],
    },
    groups: {
        groups: [],
    },
    kpis: {
        kpis: [],
    },
    miniTableKpis: {
        kpis: [],
    },
    locations: {
        locationLayers: [],
    },
    locationsLayersCodes: [],
    groupsWithCodes: [],
    graphsData: {
        primaryAxis: [],
        secondaryAxis: [],
        tertiaryAxis: [],
    },
    mapData: [],
    availableLocationsTreeCodes: [],
    allLocationsTreeCodes: [],
    availableKpis: [],
    kpisTable: {
        limit: PAGINATION.LIMIT,
        page: 1,
        count: 0,
        results: []
    },
};

export const reducer = (state = { ...defaultState, ...defaultParamsState }, action) => {
    switch (action.type) {
        case FORECASTING_TYPES.GET_FORECASTING_DOCUMENTS.PENDING:
            return {
                ...state,
                getForecastingDocumentsPending: true,
            };
        case FORECASTING_TYPES.GET_FORECASTING_DOCUMENTS.FULFILLED:
            return {
                ...state,
                documents: action.payload,
                getForecastingDocumentsPending: false,
            };
        case FORECASTING_TYPES.GET_FORECASTING_DOCUMENTS.REJECTED:
            return {
                ...state,
                getForecastingDocumentsPending: false,
            };

        case FORECASTING_TYPES.UPDATE_FORECASTING_DOCUMENTS.PENDING:
            return {
                ...state,
                updateForecastingDocumentsPending: true,
            };
        case FORECASTING_TYPES.UPDATE_FORECASTING_DOCUMENTS.FULFILLED:
            return {
                ...state,
                documents: action.payload,
                updateForecastingDocumentsPending: false,
            };
        case FORECASTING_TYPES.UPDATE_FORECASTING_DOCUMENTS.REJECTED:
            return {
                ...state,
                updateForecastingDocumentsPending: false,
            };
        case FORECASTING_TYPES.CHOOSE_GENERATE: {
            const previousDataName = state.generateDataName;
            return {
                ...state,
                previousDataName,
                generateDataName: action.generateDataName,
            };
        }

        case FORECASTING_TYPES.GET_DATA_LAKE_SECTORS.PENDING:
            return {
                ...state,
                getDataLakeSectorsPending: true,
            };
        case FORECASTING_TYPES.GET_DATA_LAKE_SECTORS.FULFILLED:
            return {
                ...state,
                sectors: action.sectors,
                getDataLakeSectorsPending: false,
            };
        case FORECASTING_TYPES.GET_DATA_LAKE_SECTORS.REJECTED:
            return {
                ...state,
                getDataLakeSectorsPending: false,
            };

        case FORECASTING_TYPES.GET_DATA_LAKE_SCENARIOS.PENDING:
            return {
                ...state,
                getDataLakeScenariosPending: true,
            };
        case FORECASTING_TYPES.GET_DATA_LAKE_SCENARIOS.FULFILLED:
            return {
                ...state,
                scenarios: action.scenarios,
                getDataLakeScenariosPending: false,
            };
        case FORECASTING_TYPES.GET_DATA_LAKE_SCENARIOS.REJECTED:
            return {
                ...state,
                getDataLakeScenariosPending: false,
            };

        case FORECASTING_TYPES.GET_DATA_LAKE_GROUPS.PENDING:
            return {
                ...state,
                getDataLakeGroupsPending: true,
            };
        case FORECASTING_TYPES.GET_DATA_LAKE_GROUPS.FULFILLED:
            return {
                ...state,
                groups: action.groups,
                availableKpis: action.availableKpis,
                allKpis: action.allKpis,
                allGroupsAndKpis: action.allGroupsAndKpis,
                groupsWithCodes: action.groupsWithCodes,
                getDataLakeGroupsPending: false,
            };
        case FORECASTING_TYPES.GET_DATA_LAKE_GROUPS.REJECTED:
            return {
                ...state,
                getDataLakeGroupsPending: false,
            };

        case FORECASTING_TYPES.GET_DATA_LAKE_LOCATION_LAYERS.PENDING:
            return {
                ...state,
                getDataLakeLocationsPending: true,
            };
        case FORECASTING_TYPES.GET_DATA_LAKE_LOCATION_LAYERS.FULFILLED:
            return {
                ...state,
                locations: action.locations,
                locationsLayersCodes: action.locations.locationLayers.map(loc => ({ level: loc.level, codes: loc.locations.map(l => l.id_geo2) })),
                locationLevelName: action.locations.locationLayers.map(loc => ({ level: loc.level, name: loc.name })),
                groupsLocationsIds: action.groupsLocationsIds,
                getDataLakeLocationsPending: false,
            };
        case FORECASTING_TYPES.GET_DATA_LAKE_LOCATION_LAYERS.REJECTED:
            return {
                ...state,
                getDataLakeLocationsPending: false,
            };
        case FORECASTING_TYPES.GET_MEASUREMENTS.PENDING:
            return {
                ...state,
                getMeasurementsPending: true,
            };
        case FORECASTING_TYPES.GET_MEASUREMENTS.FULFILLED:
            return {
                ...state,
                measurements: action.measurements,
                getMeasurementsPending: false,
            };
        case FORECASTING_TYPES.GET_MEASUREMENTS.REJECTED:
            return {
                ...state,
                getMeasurementsPending: false,
            };

        case FORECASTING_TYPES.GET_FREQUENCIES.PENDING:
            return {
                ...state,
                getFrequenciesPending: true,
            };
        case FORECASTING_TYPES.GET_FREQUENCIES.FULFILLED:
            return {
                ...state,
                frequencies: action.frequencies,
                getFrequenciesPending: false,
            };
        case FORECASTING_TYPES.GET_FREQUENCIES.REJECTED:
            return {
                ...state,
                getFrequenciesPending: false,
            };

        case FORECASTING_TYPES.GET_TIME_SPAN.PENDING:
            return {
                ...state,
                getTimeSpanPending: true,
            };
        case FORECASTING_TYPES.GET_TIME_SPAN.FULFILLED:
            return {
                ...state,
                timeSpan: action.timeSpan,
                getTimeSpanPending: false,
            };
        case FORECASTING_TYPES.GET_TIME_SPAN.REJECTED:
            return {
                ...state,
                getTimeSpanPending: false,
            };

        case FORECASTING_TYPES.GET_MINI_TABLE_KPI_VALUES.PENDING:
            return {
                ...state,
                getMiniTableKpiValuesPending: true,
            };
        case FORECASTING_TYPES.GET_MINI_TABLE_KPI_VALUES.FULFILLED:
            return {
                ...state,
                miniTableKpis: action.miniTableKpis,
                getMiniTableKpiValuesPending: false,
            };
        case FORECASTING_TYPES.GET_MINI_TABLE_KPI_VALUES.REJECTED:
            return {
                ...state,
                getMiniTableKpiValuesPending: false,
            };

        case FORECASTING_TYPES.GET_DATA_LAKE_VALUES_TABLE.PENDING:
            return {
                ...state,
                getDataLakeValuesTablePending: true,
            };
        case FORECASTING_TYPES.GET_DATA_LAKE_VALUES_TABLE.FULFILLED:
            return {
                ...state,
                kpisTable: action.kpisTable,
                getDataLakeValuesTablePending: false,
            };
        case FORECASTING_TYPES.GET_DATA_LAKE_VALUES_TABLE.REJECTED:
            return {
                ...state,
                getDataLakeValuesTablePending: false,
            };

        case FORECASTING_TYPES.DOWNLOAD_EXCEL_TABLE.PENDING:
            return {
                ...state,
                downloadExcelTablePending: true,
            };
        case FORECASTING_TYPES.DOWNLOAD_EXCEL_TABLE.FULFILLED:
            return {
                ...state,
                downloadExcelTablePending: false,
            };
        case FORECASTING_TYPES.DOWNLOAD_EXCEL_TABLE.REJECTED:
            return {
                ...state,
                downloadExcelTablePending: false,
            };

        case FORECASTING_TYPES.GET_TABLE_ROW_COUNT.PENDING:
            return {
                ...state,
                getTableRowCountPending: true,
            };
        case FORECASTING_TYPES.GET_TABLE_ROW_COUNT.FULFILLED:
            return {
                ...state,
                rowCount: action.rowCount,
                getTableRowCountPending: false,
            };
        case FORECASTING_TYPES.GET_TABLE_ROW_COUNT.REJECTED:
            return {
                ...state,
                getTableRowCountPending: false,
            };

        case FORECASTING_TYPES.GET_LOCATIONS_TREE.PENDING:
            return {
                ...state,
                getLocationsTreePending: true,
            };
        case FORECASTING_TYPES.GET_LOCATIONS_TREE.FULFILLED:
            return {
                ...state,
                locationsTree: action.locationsTree,
                availableLocationsTreeCodes: action.availableLocationsTreeCodes,
                allLocationsTreeCodes: action.allLocationsTreeCodes,
                keyNameLocations: action.keyNameLocations,
                getLocationsTreePending: false,
            };
        case FORECASTING_TYPES.GET_LOCATIONS_TREE.REJECTED:
            return {
                ...state,
                availableLocationsTreeCodes: [],
                allLocationsTreeCodes: [],
                getLocationsTreePending: false,
            };

        case FORECASTING_TYPES.CLEAR_DATA_LAKE_STATE: {
            const clearedState = {
                ...defaultParamsState
            };
            if (action.withoutKeys && action.withoutKeys.length > 0) {
                action.withoutKeys.forEach(key => {
                    delete clearedState[key];
                });
            }
            return {
                ...state,
                ...clearedState
            };
        }
        case FORECASTING_TYPES.SET_GRAPH_DATA:
            return {
                ...state,
                graphsData: {
                    ...state.graphsData,
                    [action.graphsData.axis]: action.graphsData.kpis,
                },
            };
        case FORECASTING_TYPES.CLEAR_GRAPH_DATA:
            return {
                ...state,
                graphsData: {
                    primaryAxis: [],
                    secondaryAxis: [],
                    tertiaryAxis: [],
                },
            };
        case FORECASTING_TYPES.SET_MAP_DATA:
            return {
                ...state,
                mapData: action.mapData
            };
        case FORECASTING_TYPES.CLEAR_MAP_DATA:
            return {
                ...state,
                mapData: [],
            };
        case FORECASTING_TYPES.RESET_DATA_CHANGE_SECTOR: {
            return {
                ...defaultState,
                ...defaultParamsState,
                allLocationsTreeCodes: [...state.allLocationsTreeCodes],
                availableLocationsTreeCodes: [...state.availableLocationsTreeCodes],
                sectors: {
                    ...state.sectors,
                },
                locationsTree: {
                    ...state.locationsTree
                },
                keyNameLocations: [...state.keyNameLocations]
            };
        }
        default:
            return state;
    }
};

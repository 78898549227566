import { CONFIG as CONFIG_DEV } from "./dev";
import { CONFIG as CONFIG_LOCAL } from "./dev-local";
import { CONFIG as CONFIG_PROD } from "./prod";
import { CONFIG as CONFIG_PROXY } from "./proxy";
import { CONFIG as CONFIG_STAGING } from "./staging";
// process.env.TARGET_ENV = "PROXY";

const getConfig = () => {
  switch (process.env.TARGET_ENV) {
    case "DEV":
      return CONFIG_DEV;
    case "STAGING":
      return CONFIG_STAGING;
    case "PROD":
      return CONFIG_PROD;
    case "PROXY":
      return CONFIG_PROXY;
    default:
      return CONFIG_LOCAL;
  }
};

export const CONFIG = getConfig();

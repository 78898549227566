import { TYPES } from "actionTypes/user";
import axios from "axios";
import { API_URLS } from "constants/apiUrl";
import ReactGA from "react-ga";
import { getMyProfile } from "actions/users";
import { CONFIG } from "../config";

export const setUserToState = (user) => {
  return async (dispatch) => {
    await dispatch({
      type: TYPES.LOGIN.FULFILLED,
      user,
      isLogged: true,
    });
  };
};

export const login = (externalToken) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.LOGIN.PENDING,
    });
    try {
      const res = await axios.post(
        API_URLS.LOGIN,
        {},
        {
          headers: {
            Authorization: `Bearer ${externalToken}`,
          },
        }
      );
      return res;
    } catch (e) {
      dispatch({
        type: TYPES.LOGIN.REJECTED,
        isLogged: false,
      });
      throw e;
    }
  };
};

export const setCookies = (isAgree) => async (dispatch) => {
  dispatch({
    type: TYPES.SET_COOKIES.PENDING,
  });
  try {
    await axios.patch(API_URLS.SET_COOKIES, { cookies: isAgree });
    dispatch({
      type: TYPES.SET_COOKIES.FULFILLED,
    });
    dispatch(getMyProfile());
  } catch (error) {
    dispatch({
      type: TYPES.SET_COOKIES.REJECTED,
    });
    throw error;
  }
};

export const logout = () => ({
  type: TYPES.LOGOUT,
  isLogged: false,
});

export const serverVersion = () => async (dispatch) => {
  dispatch({
    type: TYPES.SERVER_STATUS.PENDING,
  });
  try {
    const data = await axios.get(API_URLS.SERVER_STATUS);
    dispatch({
      type: TYPES.SERVER_STATUS.FULFILLED,
      serverStatus: data.data,
    });
  } catch (error) {
    dispatch({
      type: TYPES.SERVER_STATUS.REJECTED,
    });
    throw error;
  }
};

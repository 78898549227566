/**
 * Created by kurtwagner on 17.04.2018
 */

export const FONTSIZES = {
  FONTSIZE3: "20px",
  FONTSIZE1: "32px",
  FONTSIZE4: "12px",
  FONTSIZE14: "14px",
  FONTSIZE16: "16px",
  FONTSIZE18: "18px",
  FONTSIZE20: "20px",
  FONTSIZE24: "24px",
  FONTSIZE34: "34px",
  HEADER: "1.7em",
};

import { FILES_ACTION_TYPES } from "actionTypes/files";

const defaultState = {
  getFilesRootPending: false,
  createURLDashboardPending: false,
  createDocumentPending: false,
  getFolderPending: false,
  getFilePending: false,
  getFilesPending: false,
  searchFilesPending: false,
  updateFilePending: false,
  deleteFilePending: false,
  updateModulePending: false,
  getSingleDashboardPending: false,
  getDEMOFolderPending: false,
  urlDashboard: "",
  dashboard: {},
  files: [],
  reports: [],
  filesRoot: {
    files: [],
  },
  DEMOFolders: [],
  file: {
    groups: [],
    tags: [],
    metadata: {},
  },
  folder: {},
  newsSearched: [],
  documentsSearched: [],
  workspacesSearched: [],
  createRootModulePending: false,
  updateRootModulePending: false,
  deleteRootModulePending: false,
};

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case FILES_ACTION_TYPES.GET_FILES_ROOT.PENDING:
      return {
        ...state,
        getFilesRootPending: true,
      };
    case FILES_ACTION_TYPES.GET_FILES_ROOT.FULFILLED:
      return {
        ...state,
        getFilesRootPending: false,
        filesRoot: action.filesRoot,
      };
    case FILES_ACTION_TYPES.GET_FILES_ROOT.REJECTED:
      return {
        ...state,
        getFilesRootPending: false,
      };

    case FILES_ACTION_TYPES.CREATE_URL_DASHBOARD.PENDING:
      return {
        ...state,
        createURLDashboardPending: true,
      };
    case FILES_ACTION_TYPES.CREATE_URL_DASHBOARD.FULFILLED:
      return {
        ...state,
        createURLDashboardPending: false,
        urlDashboard: action.urlDashboard,
      };
    case FILES_ACTION_TYPES.CREATE_URL_DASHBOARD.REJECTED:
      return {
        ...state,
        createURLDashboardPending: false,
        urlDashboard: action.urlDashboard,
      };
    case FILES_ACTION_TYPES.CREATE_URL_DASHBOARD.CLEAR:
      return {
        ...state,
        urlDashboard: "",
        updateModulePending: false,
      };

    case FILES_ACTION_TYPES.CREATE_DOCUMENT.PENDING:
      return {
        ...state,
        createDocumentPending: true,
      };
    case FILES_ACTION_TYPES.CREATE_DOCUMENT.FULFILLED:
      return {
        ...state,
        createDocumentPending: false,
        urlDashboard: action.urlDashboard,
      };
    case FILES_ACTION_TYPES.CREATE_DOCUMENT.REJECTED:
      return {
        ...state,
        createDocumentPending: false,
      };

    case FILES_ACTION_TYPES.GET_FOLDER.PENDING:
      return {
        ...state,
        getFolderPending: true,
      };
    case FILES_ACTION_TYPES.GET_FOLDER.FULFILLED:
      return {
        ...state,
        getFolderPending: false,
        folder: action.folder,
        files: action.files,
      };
    case FILES_ACTION_TYPES.GET_FOLDER.REJECTED:
      return {
        ...state,
        getFolderPending: false,
      };

    // * DEMO

    case FILES_ACTION_TYPES.GET_DEMO_FOLDER.PENDING:
      return {
        ...state,
        getDEMOFolderPending: true,
      };
    case FILES_ACTION_TYPES.GET_DEMO_FOLDER.FULFILLED:
      return {
        ...state,
        getDEMOFolderPending: false,
        DEMOFolders: [...state.DEMOFolders, action.folder],
      };
    case FILES_ACTION_TYPES.GET_DEMO_FOLDER.REJECTED:
      return {
        ...state,
        getDEMOFolderPending: false,
      };

    case FILES_ACTION_TYPES.GET_FILE.PENDING:
      return {
        ...state,
        getFilePending: true,
      };
    case FILES_ACTION_TYPES.GET_FILE.FULFILLED:
      return {
        ...state,
        getFilePending: false,
        file: action.payload,
      };
    case FILES_ACTION_TYPES.GET_FILE.REJECTED:
      return {
        ...state,
        getFilePending: false,
      };
    case FILES_ACTION_TYPES.GET_FILE.CLEAR:
      return {
        ...state,
        file: {},
        updateModulePending: false,
      };
    case FILES_ACTION_TYPES.GET_FILES.PENDING:
      return {
        ...state,
        getFilesPending: true,
      };
    case FILES_ACTION_TYPES.GET_FILES.FULFILLED:
      if ("reports" in action.payload) {
        return {
          ...state,
          getFilesPending: false,
          reports: action.payload,
        };
      }
      return {
        ...state,
        getFilesPending: false,
        files: action.payload,
      };
    case FILES_ACTION_TYPES.GET_FILES.REJECTED:
      return {
        ...state,
        getFilesPending: false,
      };
    case FILES_ACTION_TYPES.SEARCH_FILES.PENDING:
      return {
        ...state,
        searchFilesPending: true,
      };
    case FILES_ACTION_TYPES.SEARCH_FILES.FULFILLED:
      const { type, response } = action.payload;
      switch (type) {
        case "reports":
          return {
            ...state,
            searchFilesPending: false,
            documentsSearched: response.results,
            countReports: response.count,
            currentReportPage: response.page,
          };
        case "news":
          return {
            ...state,
            searchFilesPending: false,
            newsSearched: response.results,
            countNews: response.count,
            currentNewPage: response.page,
          };
        case "reports":
          return {
            ...state,
            searchFilesPending: false,
            documentsSearched: response.results,
            countReports: response.count,
            currentReportPage: response.page,
          };
        case "workspaces":
          return {
            ...state,
            searchFilesPending: false,
            workspacesSearched: response.results,
            countWorkspaces: response.count,
            currentWorkspacePage: response.page,
          };

        default:
          return {
            ...state,
            searchFilesPending: false,
            files: response.results,
          };
      }

    case FILES_ACTION_TYPES.SEARCH_FILES.REJECTED:
      return {
        ...state,
        searchFilesPending: false,
      };
    case FILES_ACTION_TYPES.UPDATE_FILE.PENDING:
      return {
        ...state,
        updateFilePending: true,
      };
    case FILES_ACTION_TYPES.UPDATE_FILE.FULFILLED:
      return {
        ...state,
        updateFilePending: false,
      };
    case FILES_ACTION_TYPES.UPDATE_FILE.REJECTED:
      return {
        ...state,
        updateFilePending: false,
      };
    case FILES_ACTION_TYPES.DELETE_FILE.PENDING:
      return {
        ...state,
        deleteFilePending: true,
      };
    case FILES_ACTION_TYPES.DELETE_FILE.FULFILLED:
      return {
        ...state,
        deleteFilePending: false,
      };
    case FILES_ACTION_TYPES.DELETE_FILE.REJECTED:
      return {
        ...state,
        deleteFilePending: false,
      };

    case FILES_ACTION_TYPES.GET_SINGLE_DASHBOARD.PENDING:
      return {
        ...state,
        getSingleDashboardPending: true,
      };
    case FILES_ACTION_TYPES.GET_SINGLE_DASHBOARD.FULFILLED:
      return {
        ...state,
        dashboard: action.dashboard,
        getSingleDashboardPending: false,
      };
    case FILES_ACTION_TYPES.GET_SINGLE_DASHBOARD.REJECTED:
      return {
        ...state,
        getSingleDashboardPending: false,
      };

    case FILES_ACTION_TYPES.UPDATE_MODULE.PENDING:
      return {
        ...state,
        updateModulePending: true,
      };
    case FILES_ACTION_TYPES.UPDATE_MODULE.FULFILLED:
      return {
        ...state,
        module: action.module,
        updateModulePending: false,
      };
    case FILES_ACTION_TYPES.UPDATE_MODULE.REJECTED:
      return {
        ...state,
        updateModulePending: false,
      };
    case FILES_ACTION_TYPES.UPDATE_MODULE.CLEAR:
      return {
        ...state,
        module: {},
        updateModulePending: false,
      };
    case FILES_ACTION_TYPES.CREATE_ROOT_MODULE.PENDING:
      return {
        ...state,
        createRootModulePending: true,
      };
    case FILES_ACTION_TYPES.CREATE_ROOT_MODULE.FULFILLED:
      return {
        ...state,
        createRootModulePending: false,
      };
    case FILES_ACTION_TYPES.CREATE_ROOT_MODULE.REJECTED:
      return {
        ...state,
        createRootModulePending: false,
      };
    case FILES_ACTION_TYPES.UPDATE_ROOT_MODULE.PENDING:
      return {
        ...state,
        updateRootModulePending: true,
      };
    case FILES_ACTION_TYPES.UPDATE_ROOT_MODULE.FULFILLED:
      return {
        ...state,
        updateRootModulePending: false,
      };
    case FILES_ACTION_TYPES.UPDATE_ROOT_MODULE.REJECTED:
      return {
        ...state,
        updateRootModulePending: false,
      };
    case FILES_ACTION_TYPES.DELETE_ROOT_MODULE.PENDING:
      return {
        ...state,
        deleteRootModulePending: true,
      };
    case FILES_ACTION_TYPES.DELETE_ROOT_MODULE.FULFILLED:
      return {
        ...state,
        deleteRootModulePending: false,
      };
    case FILES_ACTION_TYPES.DELETE_ROOT_MODULE.REJECTED:
      return {
        ...state,
        deleteRootModulePending: false,
      };
    default:
      return state;
  }
};

import { TYPES } from "actionTypes/groups";

const defaultState = {
  groups: {
    results: [],
    page: 1,
    limit: 20,
    count: 0,
  },
  group: {
    files: [],
    dashboards: [],
    folders: [],
    assignedFiles: [],
    documents: [],
    modules: [],
    news: [],
  },
  getGroupsPending: false,
  getFilePermissionsPending: false,
  getGroupPending: false,
  createGroupPending: false,
  editGroupPending: false,
  deleteGroupPending: false,
  getDashboardsPending: false,
  getFoldersPending: false,
  getFoldersTreePending: false,
  getDashboardsTreePending: false,
  cloneGroupPending: false,
  files: [],
  dashboards: [],
  filePermissions: null,
  foldersTree: [],
  dashboardsTree: [],
  permissionTrees: [],
};

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case TYPES.CLONE_GROUP.PENDING:
      return {
        ...state,
        cloneGroupPending: true,
      };
    case TYPES.CLONE_GROUP.FULFILLED:
      return {
        ...state,

        cloneGroupPending: false,
      };
    case TYPES.GET_GROUPS.PENDING:
      return {
        ...state,
        getGroupsPending: true,
      };
    case TYPES.GET_GROUPS.FULFILLED:
      return {
        ...state,
        groups: action.groups,
        getGroupsPending: false,
      };
    case TYPES.GET_GROUP.PENDING:
      return {
        ...state,
        getGroupPending: true,
      };
    case TYPES.GET_GROUP.FULFILLED:
      return {
        ...state,
        group: action.group,
        getGroupPending: false,
      };
    case TYPES.CREATE_GROUP.PENDING:
      return {
        ...state,
        createGroupPending: true,
      };
    case TYPES.CREATE_GROUP.FULFILLED:
      return {
        ...state,
        createGroupPending: false,
      };
    case TYPES.CREATE_GROUP.REJECTED:
      return {
        ...state,
        createGroupPending: false,
      };
    case TYPES.EDIT_GROUP.PENDING:
      return {
        ...state,
        editGroupPending: true,
      };
    case TYPES.EDIT_GROUP.FULFILLED:
      return {
        ...state,
        editGroupPending: false,
      };
    case TYPES.EDIT_GROUP.REJECTED:
      return {
        ...state,
        editGroupPending: false,
      };
    case TYPES.DELETE_GROUP.PENDING:
      return {
        ...state,
        deleteGroupPending: true,
      };
    case TYPES.DELETE_GROUP.FULFILLED:
      return {
        ...state,
        deleteGroupPending: false,
      };
    case TYPES.DELETE_GROUP.REJECTED:
      return {
        ...state,
        deleteGroupPending: false,
      };
    case TYPES.GET_FILE_PERMISSIONS.PENDING:
      return {
        ...state,
        getFilePermissionsPending: true,
      };
    case TYPES.GET_FILE_PERMISSIONS.FULFILLED:
      return {
        ...state,
        filePermissions: action.dashboards,
        getFilePermissionsPending: false,
      };
    case TYPES.GET_FILE_PERMISSIONS.REJECTED:
      return {
        ...state,
        getFilePermissionsPending: false,
      };
    case TYPES.GET_DASHBOARDS.PENDING:
      return {
        ...state,
        getDashboardsPending: true,
      };
    case TYPES.GET_DASHBOARDS.FULFILLED:
      return {
        ...state,
        dashboards: action.dashboards,
        getDashboardsPending: false,
      };
    case TYPES.GET_DASHBOARDS.REJECTED:
      return {
        ...state,
        getDashboardsPending: false,
      };
    case TYPES.GET_ALL_DOCUMENTS.PENDING:
      return {
        ...state,
        getFoldersTreePending: true,
      };
    case TYPES.GET_ALL_DOCUMENTS.FULFILLED:
      return {
        ...state,
        foldersTree: action.foldersTree,
        getFoldersTreePending: false,
      };
    case TYPES.GET_ALL_DOCUMENTS.REJECTED:
      return {
        ...state,
        getFoldersTreePending: false,
      };
    case TYPES.GET_DASHBOARDS_TREE.PENDING:
      return {
        ...state,
        getDashboardsTreePending: true,
      };
    case TYPES.GET_DASHBOARDS_TREE.FULFILLED:
      return {
        ...state,
        dashboardsTree: action.dashboardsTree,
        getDashboardsTreePending: false,
      };
    case TYPES.GET_DASHBOARDS_TREE.REJECTED:
      return {
        ...state,
        getDashboardsTreePending: false,
      };
    default:
      return state;
  }
};

import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as ui } from "react-redux-ui-tools";
import { reducer as files } from "reducers/files";
import { reducer as user } from "reducers/user";
import { reducer as users } from "reducers/users";
import { reducer as groups } from "reducers/groups";
import { reducer as tags } from "reducers/tags";
import { reducer as breadcrumbs } from "reducers/breadcrumbs";
import { reducer as news } from "reducers/news";
import { reducer as forecasting } from "reducers/forecasting";
import { reducer as notifications } from "reducers/notifications";
import { reducer as socket } from "reducers/socket";
import { reducer as events } from "reducers/events";
import { reducer as favQueries } from "reducers/favQueries";
import { reducer as lateralMenu } from "reducers/lateralMenu";
import { reducer as dataLakePermissions } from "reducers/dataLakePermissions";
import { reducer as workspaces } from "reducers/workspaces";
import { reducer as legalNotice } from "reducers/./legalNotice";
import { reducer as aboutUs } from "reducers/aboutUs";
import { reducer as tagManager } from "reducers/tagManager";
import { reducer as searchBy } from "reducers/./searchBy";
import { reducer as AIModule } from "reducers/aimodule";
export const reducers = combineReducers({
  form: formReducer,
  ui,
  user,
  files,
  users,
  groups,
  tags,
  breadcrumbs,
  news,
  AIModule,
  forecasting,
  notifications,
  socket,
  events,
  favQueries,
  lateralMenu,
  dataLakePermissions,
  workspaces,
  legalNotice,
  aboutUs,
  tagManager,
  searchBy,
});

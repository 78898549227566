import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STYLES_CONSTANTS } from 'styles/constants/constants';
import { Icon } from 'components/Icon';

const SIconButton = styled.button`
    display: flex;
    margin: auto 10px;
    align-items: center;
    font-size: 13px;
    color: ${({ fill }) => fill || STYLES_CONSTANTS.COLORS.DARK_GREEN_2};
    span {
        margin-left: 7px;
        color: ${STYLES_CONSTANTS.COLORS.DARK_GREEN_2};
    }
`;

export const IconButton = (props) => {
    const { path, fill, text, type } = props;
    return (
        <SIconButton {...props} type={type}>
            <Icon path={path} style={{ color: fill }} />
            {text && <span>{text}</span>}
        </SIconButton>
    );
};

IconButton.defaultProps = {
    text: '',
    fill: 'none',
    type: 'button',
    onClick: () => {},
};

IconButton.propTypes = {
    path: PropTypes.string.isRequired,
    text: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    type: PropTypes.string,
};

export const SIconButtonMail = styled(IconButton)`
    position:fixed;
    bottom: 32px;
    right: 32px;
    justify-content: center;
    margin: 25px 0 0 auto;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${STYLES_CONSTANTS.COLORS.DARK_GREEN_2};
    z-index: 10;
`;

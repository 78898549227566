import { NEWS_ACTION_TYPES } from "actionTypes/news";

const defaultState = {
  getNewsPending: false,
  getHighlightedNewsPending: false,
  getSingleNewsPending: false,
  createNewsPending: false,
  updateNewsPending: false,
  deleteNewsPending: false,
  news: {
    results: [],
    page: 1,
    limit: 20,
    count: 0,
  },
  highlightedNews: {
    results: [],
    page: 1,
    limit: 6,
    count: 0,
  },
  singleNews: {
    news: {
      file: {},
    },
    groups: [],
    tags: [],
  },
  newsResults: [],
};

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case NEWS_ACTION_TYPES.CREATE_NEWS.PENDING:
      return {
        ...state,
        createNewsPending: true,
      };
    case NEWS_ACTION_TYPES.CREATE_NEWS.FULFILLED:
      return {
        ...state,
        createNewsPending: false,
      };
    case NEWS_ACTION_TYPES.CREATE_NEWS.REJECTED:
      return {
        ...state,
        createNewsPending: false,
      };
    case NEWS_ACTION_TYPES.GET_NEWS.PENDING:
      return {
        ...state,
        getNewsPending: true,
      };
    case NEWS_ACTION_TYPES.GET_NEWS.FULFILLED: {
      return {
        ...state,
        getNewsPending: false,
        news: action.payload,
        newsResults: action.payload.results,
      };
    }
    case NEWS_ACTION_TYPES.CLEAR_NEWS: {
      return {
        ...state,
        getNewsPending: false,
        news: [],
        newsResults: [],
      };
    }
    case NEWS_ACTION_TYPES.GET_NEWS.REJECTED:
      return {
        ...state,
        getNewsPending: false,
      };
    case NEWS_ACTION_TYPES.GET_HIGHLIGHTED_NEWS.PENDING:
      return {
        ...state,
        getHighlightedNewsPending: true,
      };
    case NEWS_ACTION_TYPES.GET_HIGHLIGHTED_NEWS.FULFILLED:
      return {
        ...state,
        getHighlightedNewsPending: false,
        highlightedNews: action.payload,
      };

    case NEWS_ACTION_TYPES.GET_HIGHLIGHTED_NEWS.REJECTED:
      return {
        ...state,
        getHighlightedNewsPending: false,
      };
    case NEWS_ACTION_TYPES.GET_SINGLE_NEWS.PENDING:
      return {
        ...state,
        getSingleNewsPending: true,
      };
    case NEWS_ACTION_TYPES.GET_SINGLE_NEWS.FULFILLED:
      return {
        ...state,
        getSingleNewsPending: false,
        singleNews: action.payload,
      };
    case NEWS_ACTION_TYPES.GET_SINGLE_NEWS.REJECTED:
      return {
        ...state,
        getSingleNewsPending: false,
      };
    case NEWS_ACTION_TYPES.UPDATE_NEWS.PENDING:
      return {
        ...state,
        updateNewsPending: true,
      };
    case NEWS_ACTION_TYPES.UPDATE_NEWS.FULFILLED:
      return {
        ...state,
        updateNewsPending: false,
      };
    case NEWS_ACTION_TYPES.UPDATE_NEWS.REJECTED:
      return {
        ...state,
        updateNewsPending: false,
      };
    case NEWS_ACTION_TYPES.DELETE_NEWS.PENDING:
      return {
        ...state,
        deleteNewsPending: true,
      };
    case NEWS_ACTION_TYPES.DELETE_NEWS.FULFILLED:
      return {
        ...state,
        deleteNewsPending: false,
      };
    case NEWS_ACTION_TYPES.DELETE_NEWS.REJECTED:
      return {
        ...state,
        deleteNewsPending: false,
      };
    default:
      return state;
  }
};

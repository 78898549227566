import "babel-polyfill";
import React, { Fragment, Component } from "react";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
// import ReactGA from 'react-ga';
import { install } from "ga-gtag";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CookiesProvider } from "react-cookie";
import { withModal } from "HOC/withModal";
import styledNormalize from "styled-normalize";
import { GlobalStyles } from "styles/globalStyles";
import { AuthProvider } from "providers/auth";
import { TableauStructureMenuMiddlewareProvider } from "./providers/TableauStructureMenuMiddlewareProvider";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { SupportContainer } from "./containers/SupportContainer";
const queryClient = new QueryClient();

import { CONFIG } from "./config";
import { store } from "./store";
import { Routes } from "./views/routes";
import { ErrorFallback } from "./modules/error-fallback";

// if (CONFIG.ENVIRONMENT !== 'local') {
//     Sentry.init({
//         dsn: 'https://038890b68d0042e3b1831d5b3708bff5@o512466.ingest.sentry.io/5619072',
//         environment: CONFIG.ENVIRONMENT
//     });
// }

const GlobalStyle = createGlobalStyle`
    ${styledNormalize}
    ${GlobalStyles}
`;

// ga-gtag
install(CONFIG.GOOGLE_ANALYTICS_ID);

class _App extends Component {
  static propTypes = {
    modalProps: PropTypes.shape({
      supportMail: PropTypes.object,
    }).isRequired,
  };

  render() {
    return (
      <Fragment>
        <GlobalStyle />
        <Sentry.ErrorBoundary fallback={(e) => <ErrorFallback e={e} />}>
          <base target="_blank" />
          <ToastContainer />
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <CookiesProvider>
                <TableauStructureMenuMiddlewareProvider>
                  <HashRouter>
                    <AuthProvider>
                      <Routes />
                      <SupportContainer {...this.props.modalProps} />
                    </AuthProvider>
                  </HashRouter>
                </TableauStructureMenuMiddlewareProvider>
              </CookiesProvider>
            </QueryClientProvider>
          </Provider>
        </Sentry.ErrorBoundary>
      </Fragment>
    );
  }
}

export const App = withModal(_App, ["supportMail"]);

render(<App />, document.getElementById("main"));

import { createAsyncActionConstants } from 'util/util';

export const WORKSPACES_ACTION_TYPES = {
    GET_ADMIN_SHARED_FOLDERS: createAsyncActionConstants('GET_ADMIN_SHARED_FOLDERS'),
    CREATE_ADMIN_SHARED_FOLDER: createAsyncActionConstants('CREATE_ADMIN_SHARED_FOLDER'),
    UPDATE_ADMIN_SHARED_FOLDER: createAsyncActionConstants('UPDATE_ADMIN_SHARED_FOLDER'),
    DELETE_ADMIN_SHARED_FOLDER: createAsyncActionConstants('DELETE_ADMIN_SHARED_FOLDER'),

    GET_SHARED_FOLDERS: createAsyncActionConstants('GET_SHARED_FOLDERS'),
    CREATE_SHARED_FILE: createAsyncActionConstants('CREATE_FILE'),
    UPDATE_SHARED_FILE: createAsyncActionConstants('UPDATE_FILE'),
    DELETE_SHARED_FILE: createAsyncActionConstants('DELETE_FILE'),

    GET_SHARED_FOLDER: createAsyncActionConstants('GET_SHARED_FOLDER'),
};

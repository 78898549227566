import React from "react";
import styled, { keyframes } from "styled-components";
import { STYLES_CONSTANTS } from 'styles/constants/constants';

const ShineAnimation = keyframes`
  0% {transform:translateX(-100%);}
	100% {transform:translateX(100%);}
`;

const SWrapper = styled.div`
  width: ${ ({ width }) =>  width || '100%' };
  height: ${ ({ height }) => height || '14px' };
  margin-bottom: ${ ({ marginBottom }) => marginBottom || '16px' };
  position: relative;
  overflow: hidden;
  background-color: ${STYLES_CONSTANTS.COLORS.LIGHT_GREY};
`;

const SSlider = styled.div`
  background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
  top:0;
	transform:translateX(100%);
	width: 100%;
	height: 100%;
	position: absolute;
	z-index:1;
	animation: ${ ShineAnimation } 1s infinite;
`;

export const SkeletonText = (props) => {
  return (
    <SWrapper { ...props }>
      <SSlider/>
    </SWrapper>
  )
};
import { DATA_LAKE_PERMISSIONS_TYPES as TYPES } from 'actionTypes/dataLakePermissions';

const defaultState = {
    createPermissionPending: false,
    getPermissionsPending: false,
    getPermissionPending: false,
    updatePermissionPending: false,
    deletePermissionPending: false,
    getAllDataPending: false,
    getAllDataSectorsPending: false,
    getSectorsPending: false,
    permissions: [],
    sectors: {
        sectors: [],
    },
    allData: {
        scenarios: {
            scenarios: [],
        },
        locationLayers: {
            locationLayers: []
        },
        groups: {
            groups: [],
        },
    },
    allDataSectors: [],
};

export const reducer = (state = defaultState, action) => {
    switch(action.type) {
        case TYPES.CREATE_PERMISSION.PENDING:
            return {
                ...state,
                createPermissionPending: true,
            };
        case TYPES.CREATE_PERMISSION.FULFILLED:
            return {
                ...state,
                createPermissionPending: false,
            };
        case TYPES.CREATE_PERMISSION.REJECTED:
            return {
                ...state,
                createPermissionPending: false,
            };

        case TYPES.GET_PERMISSIONS.PENDING:
            return {
                ...state,
                getPermissionsPending: true,
            };
        case TYPES.GET_PERMISSIONS.FULFILLED:
            return {
                ...state,
                getPermissionsPending: false,
                permissions: action.permissions,
            };
        case TYPES.GET_PERMISSIONS.REJECTED:
            return {
                ...state,
                getPermissionsPending: false,
            };

        case TYPES.UPDATE_PERMISSION.PENDING:
            return {
                ...state,
                updatePermissionPending: true,
            };
        case TYPES.UPDATE_PERMISSION.FULFILLED:
            return {
                ...state,
                updatePermissionPending: false,
            };
        case TYPES.UPDATE_PERMISSION.REJECTED:
            return {
                ...state,
                updatePermissionPending: false,
            };

        case TYPES.DELETE_PERMISSION.PENDING:
            return {
                ...state,
                deletePermissionPending: true,
            };
        case TYPES.DELETE_PERMISSION.FULFILLED:
            return {
                ...state,
                deletePermissionPending: false,
            };
        case TYPES.DELETE_PERMISSION.REJECTED:
            return {
                ...state,
                deletePermissionPending: false,
            };

        case TYPES.GET_SECTORS.PENDING:
            return {
                ...state,
                getSectorsPending: true,
            };
        case TYPES.GET_SECTORS.FULFILLED:
            return {
                ...state,
                sectors: action.sectors,
                getSectorsPending: false,
            };
        case TYPES.GET_SECTORS.REJECTED:
            return {
                ...state,
                getSectorsPending: false,
            };

        case TYPES.GET_ALL_DATA.PENDING:
            return {
                ...state,
                getAllDataPending: true,
            };
        case TYPES.GET_ALL_DATA.FULFILLED:
            return {
                ...state,
                allData: action.allData,
                getAllDataPending: false,
            };
        case TYPES.GET_ALL_DATA.REJECTED:
            return {
                ...state,
                getAllDataPending: false,
            };

        case TYPES.GET_ALL_DATA_FOR_ALL_SECTORS.PENDING:
            return {
                ...state,
                getAllDataSectorsPending: true,
            };
        case TYPES.GET_ALL_DATA_FOR_ALL_SECTORS.FULFILLED:
            return {
                ...state,
                allDataSectors: [...state.allDataSectors, action.payload],
                getAllDataSectorsPending: false,
            };
        case TYPES.GET_ALL_DATA_FOR_ALL_SECTORS.REJECTED:
            return {
                ...state,
                getAllDataSectorsPending: false,
            };
        case TYPES.CLEAR_DATA_ONE_SECTOR:
            return {
                ...state,
                allData: {
                    scenarios: {
                        scenarios: [],
                    },
                    locationLayers: {
                        locationLayers: []
                    },
                    groups: {
                        groups: [],
                    },
                },
            };
        case TYPES.CLEAR_DATA_LAKE:
            return defaultState;
        default:
            return state;
    }
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMsal, useAccount } from "@azure/msal-react";
import { LS_KEYS } from "../../constants/LSKeys";
import { CONFIG } from "../../config";
import { isMobile, isValidUrl, isNotIOSMobile } from "../../util/util";
import Error from "./screens/error";
import SuccessRocket from "./screens/success";

const SWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const isValidURLByAccess = (url) =>
  url.startsWith(CONFIG.MOBILE_ALLOWED_PROTOCOL) && isValidUrl(url);

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
export const ExternalAuthenticationSuccessView = (props) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || accounts[1] || {});
  const [urlValid, setUrlValid] = useState(true);
  const [redirectError, setRedirectError] = useState(null);
  if (!isMobile()) window.location.href = "/";
  const sessionToken = localStorage.getItem(LS_KEYS.SESSION_TOKEN);
  const redirectURI = localStorage.getItem(LS_KEYS.REDIRECT_URI) ? localStorage.getItem(LS_KEYS.REDIRECT_URI) : getCookie('redirectURI')
  console.log('[redirect]', redirectURI)
const onWeb = () => {
  localStorage.removeItem(LS_KEYS.REDIRECT_URI);
  setCookie(LS_KEYS.REDIRECT_URI, '', 0);
  window.location.href = "/";
}
useEffect(() => {
  setTimeout(() => {
  if (sessionToken && redirectURI && redirectURI !== 'undefined') {
    window.location.replace(`${redirectURI}?accessToken=${sessionToken}`);
    console.log(redirectURI)
  } 
}, [isNotIOSMobile() ? 5000 : 1000])
  

}, [redirectURI])
  return (
    <SWrapper><SuccessRocket onWeb={() => { onWeb();}} onClick={() => {window.location.replace(`${redirectURI}?accessToken=${sessionToken}`); }} /></SWrapper>
  );
};

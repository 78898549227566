import { createAsyncActionConstants } from 'util/util';

export const DATA_LAKE_PERMISSIONS_TYPES = {
    CREATE_PERMISSION: createAsyncActionConstants('CREATE_PERMISSION'),
    GET_PERMISSIONS: createAsyncActionConstants('GET_PERMISSIONS'),
    UPDATE_PERMISSION: createAsyncActionConstants('UPDATE_PERMISSION'),
    DELETE_PERMISSION: createAsyncActionConstants('DELETE_PERMISSION'),
    GET_SECTORS: createAsyncActionConstants('GET_SECTORS'),
    GET_ALL_DATA: createAsyncActionConstants('GET_ALL_DATA'),
    GET_ALL_DATA_FOR_ALL_SECTORS: createAsyncActionConstants('GET_ALL_DATA_FOR_ALL_SECTORS'),
    CLEAR_DATA_LAKE: 'CLEAR_DATA_LAKE',
    CLEAR_DATA_ONE_SECTOR: 'CLEAR_DATA_ONE_SECTOR'
};

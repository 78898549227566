import { TYPES } from 'actionTypes/tagManager';

const defaultState = {
    typeGroups: {
        results: [],
        page: 1,
        limit: 20,
        count: 0,
    },
    typeGroup: {
        name: '',
        order: 0,
        id: '',
        createdAt: '',
        updatedAt: '',
        tags: [
            {
                name: '',
                order: 0,
                id: '',
                createdAt: '',
                updatedAt: ''
            }
        ]
    },
    tag: {
        name: '',
        order: 0,
        id: '',
        createdAt: '',
        updatedAt: '',
        tagGroup: {
            id: '',
            name: '',
            order: 0,
            createdAt: '',
            updatedAt: '',
            tags: []
        }
    },
    createTypeGroupPending: false,
    getTypeGroupsPending: false,
    deleteTypeGroupPending: false,
    getTypeGroupPending: false,
    updateTypeGroupPending: false,
    createTagPending: false,
    deleteTagPending: false,
    getTagPending: false,
    updateTagPending: false,
};

export const reducer = (state = defaultState, action) => {
    switch(action.type) {
        case TYPES.CREATE_TYPE_GROUP.PENDING:
            return {
                ...state,
                createTypeGroupPending: true,
            };
        case TYPES.CREATE_TYPE_GROUP.FULFILLED:
            return {
                ...state,
                createTypeGroupPending: false,
            };
        case TYPES.CREATE_TYPE_GROUP.REJECTED:
            return {
                ...state,
                createTypeGroupPending: false,
            };
        case TYPES.GET_TYPE_GROUPS.PENDING:
            return {
                ...state,
                getTypeGroupsPending: true,
            };
        case TYPES.GET_TYPE_GROUPS.FULFILLED:
            return {
                ...state,
                typeGroups: action.typeGroups,
                getTypeGroupsPending: false,
            };
        case TYPES.GET_TYPE_GROUPS.REJECTED:
            return {
                ...state,
                getTypeGroupsPending: false,
            };
        case TYPES.DELETE_TYPE_GROUP.PENDING:
            return {
                ...state,
                deleteTypeGroupPending: true,
            };
        case TYPES.DELETE_TYPE_GROUP.FULFILLED:
            return {
                ...state,
                deleteTypeGroupPending: false,
            };
        case TYPES.DELETE_TYPE_GROUP.REJECTED:
            return {
                ...state,
                deleteTypeGroupPending: false,
            };
        case TYPES.GET_TYPE_GROUP.PENDING:
            return {
                ...state,
                getTypeGroupPending: true,
            };
        case TYPES.GET_TYPE_GROUP.FULFILLED:
            return {
                ...state,
                typeGroup: action.typeGroup,
                getTypeGroupPending: false,
            };
        case TYPES.GET_TYPE_GROUP.REJECTED:
            return {
                ...state,
                getTypeGroupPending: false,
            };
        case TYPES.UPDATE_TYPE_GROUP.PENDING:
            return {
                ...state,
                updateTypeGroupPending: true,
            };
        case TYPES.UPDATE_TYPE_GROUP.FULFILLED:
            return {
                ...state,
                updateTypeGroupPending: false,
            };
        case TYPES.UPDATE_TYPE_GROUP.REJECTED:
            return {
                ...state,
                updateTypeGroupPending: false,
            };
        case TYPES.CREATE_TAG.PENDING:
            return {
                ...state,
                createTagPending: true,
            };
        case TYPES.CREATE_TAG.FULFILLED:
            return {
                ...state,
                createTagPending: false,
            };
        case TYPES.CREATE_TAG.REJECTED:
            return {
                ...state,
                createTagPending: false,
            };
        case TYPES.DELETE_TAG.PENDING:
            return {
                ...state,
                deleteTagPending: true,
            };
        case TYPES.DELETE_TAG.FULFILLED:
            return {
                ...state,
                deleteTagPending: false,
            };
        case TYPES.DELETE_TAG.REJECTED:
            return {
                ...state,
                deleteTagPending: false,
            };
        case TYPES.GET_TAG.PENDING:
            return {
                ...state,
                getTagPending: true,
            };
        case TYPES.GET_TAG.FULFILLED:
            return {
                ...state,
                tag: action.tag,
                getTagPending: false,
            };
        case TYPES.GET_TAG.REJECTED:
            return {
                ...state,
                getTagPending: false,
            };
        case TYPES.UPDATE_TAG.PENDING:
            return {
                ...state,
                updateTagPending: true,
            };
        case TYPES.UPDATE_TAG.FULFILLED:
            return {
                ...state,
                updateTagPending: false,
            };
        case TYPES.UPDATE_TAG.REJECTED:
            return {
                ...state,
                updateTagPending: false,
            };
        default:
            return state;
    }
};

import { createAsyncActionConstants } from "util/util";

export const ACTION_TYPES = {
  GET_LATERAL_MENU: createAsyncActionConstants("GET_LATERAL_MENU"),
  CREATE_LATERAL_MENU: createAsyncActionConstants("CREATE_LATERAL_MENU"),
  EDIT_LATERAL_MENU: createAsyncActionConstants("EDIT_LATERAL_MENU"),
  DELETE_LATERAL_MENU: createAsyncActionConstants("DELETE_LATERAL_MENU"),
  CREATE_TABLEAU_DASHBOARD: createAsyncActionConstants(
    "CREATE_TABLEAU_DASHBOARD"
  ),
  EDIT_TABLEAU_DASHBOARD: createAsyncActionConstants("EDIT_TABLEAU_DASHBOARD"),
  GET_TABLEAU_STRUCTURE_MENU: createAsyncActionConstants(
    "GET_TABLEAU_STRUCTURE_MENU"
  ),
  DELETE_TABLEAU_DASHBOARD: createAsyncActionConstants(
    "DELETE_TABLEAU_DASHBOARD"
  ),
  GET_TABLEAU_STRUCTURE_MENU_ALL_DONE: "getTableauStructureMenuAllDone",
  GET_TABLEU_CHILDREN: "getTableuChildren",

  GET_DEMO_LATERAL_MENU: createAsyncActionConstants("GET_DEMO_LATERAL_MENU"),
  GET_SINGLE_DASHBOARD: createAsyncActionConstants("GET_SINGLE_DASHBOARD"),
};

import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { Switch, Route } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { AuthLogin } from "providers/auth/AuthLoginMS";
import axios from "axios";
import { node } from "prop-types";
import { CONFIG } from "../../config";
import { ExternalAuthenticationView } from "../../views/external-authentication";
import { ExternalAuthenticationSuccessView } from "../../views/external-authentication/completed";
import { ExternalAppRedirectView } from "../../views/external-authentication/redirect";
import { OktaAuthenticationView } from "../../views/okta-authentication";

const msalConfig = {
  auth: {
    clientId: CONFIG.B2C.clientID,
    authority: CONFIG.B2C.authority,
    knownAuthorities: CONFIG.B2C.knownAuthorities,
    redirectUri: CONFIG.REDIRECT_URL,
    postLogoutRedirectUri: CONFIG.REDIRECT_URL,
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message) => {
        if (
          CONFIG.ENVIRONMENT === "local" ||
          CONFIG.ENVIRONMENT === "development"
        ) {
          console.log(message); // eslint-disable-line
        }
      },
    },
  },
};

axios.defaults.baseURL = CONFIG.API_URL;
const msalInstance = new PublicClientApplication(msalConfig);

export const AuthProvider = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <Switch>
        <Route path="/external/auth" component={ExternalAuthenticationView} />
        <Route
          path="/external/completed"
          component={ExternalAuthenticationSuccessView}
        />
        <Route path={`/okta/auth`} component={OktaAuthenticationView} />
        <Route path={`/auth/whitelist`} component={OktaAuthenticationView} />
        <Route path={`/auth/logout`} component={OktaAuthenticationView} />

        <Route path="/external/app" component={ExternalAppRedirectView} />
        <AuthLogin>{children}</AuthLogin>
      </Switch>
    </MsalProvider>
  );
};

AuthProvider.propTypes = {
  children: node.isRequired,
};

import React from "react";
import { Logo } from "modules/choose-role/components/Logo";
import styled from "styled-components";
import { TextRole } from "modules/choose-role/components/TextRole";
import { STYLES_CONSTANTS } from "styles/constants/constants";

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SEmail = styled.a`
  :hover {
    color: ${STYLES_CONSTANTS.COLORS.DARK_GREEN_2};
  }
`;


export const NoAccess = ({ countdown }) => (
  <SWrapper>
    <Logo />
    <TextRole>
      You do not have the access to this application. <br /> Please contact the
      administrator:{" "}
      <SEmail href="mailto:spain.zone@cbre.com">spain.zone@cbre.com</SEmail>
    </TextRole>
    <TextRole>Redirecting to the login page in {countdown} seconds</TextRole>
  </SWrapper>

);

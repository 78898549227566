import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SText = styled.p`
    font-size: 26px;
    font-weight: 300;
    line-height: 32px;
    margin-bottom: 30px;
    text-align: center;
`;

export const TextRole = ({ children }) => <SText>{children}</SText>;

TextRole.propTypes = {
    children: PropTypes.node.isRequired,
};


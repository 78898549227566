export const ZINDEX = {
  ZINDEX_1: 1,
  ZINDEX_BUTTON: 5,
  ZINDEX_SEARCHBAR: 3,
  ZINDEX_SIDEBAR: 5,
  ZINDEX_MAP_BUTTONS: 450,
  ZINDEX_MODAL: 500,
  ZINDEX_MODAL_SPINNER: 515,
  ZINDEX_NOTIFICATION_BUTTON: 520,
  ZINDEX_FULLSCREEN: 1000,
};

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { STYLES_CONSTANTS } from "styles/constants/constants";
import { Button } from "components/Button";

const SCookies = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${STYLES_CONSTANTS.COLORS.DARK_GREEN_2_90};
  z-index: 9999;
  color: ${STYLES_CONSTANTS.COLORS.WHITE};
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const SInfo = styled.p`
  font-size: ${STYLES_CONSTANTS.FONTSIZE.default};
  line-height: 1.5;
  margin-bottom: 20px;
`;

const SButtonWrapper = styled.div`
  margin-left: auto;
`;

const SButton = styled(Button)`
  text-transform: uppercase;
`;

export const Cookies = (props) => {
  const { handleAccept, handleConfigure } = props;
  return (
    <SCookies>
      {/* eslint-disable-next-line max-len */}
      <SInfo>
        This APP, property of CBRE REAL ESTATE, S.A., uses its own cookies and
        those of third parties in order to allow browsing, elaborate statistical
        information and analyze browsing habits. You can click on the ACCEPT
        button to enable the use of cookies or select which type of cookies you
        wish to accept or reject through the CONFIGURE button.
      </SInfo>
      <SButtonWrapper>
        <SButton
          color={STYLES_CONSTANTS.COLORS.WHITE}
          bgColor={STYLES_CONSTANTS.COLORS.DARK_GREEN_2}
          onClick={handleAccept}
          border
        >
          Accept
        </SButton>
        <SButton
          color={STYLES_CONSTANTS.COLORS.DARK_GREEN_2}
          bgColor={STYLES_CONSTANTS.COLORS.WHITE}
          border
          onClick={handleConfigure}
        >
          Configure
        </SButton>
      </SButtonWrapper>
    </SCookies>
  );
};

Cookies.propTypes = {
  handleAccept: PropTypes.func.isRequired,
  handleConfigure: PropTypes.func.isRequired,
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { STYLES_CONSTANTS } from "styles/constants/constants";

const SButton = styled.button`
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor || "transparent"};
  border: ${({ border, color }) => (border ? `1px solid ${color}` : null)};
  border-radius: 24px;
  box-shadow: ${({ border }) =>
    border ? "0 0 1px 0 white inset, 0 0 1px 0 white" : null};
  padding: 8px 32px;
  height: 35px;
  font-size: ${STYLES_CONSTANTS.FONTSIZE.default};
  line-height: 18px;
  width: ${({ width }) => width || "171px"};
  margin: 0 8px;
  text-align: center;

  &:disabled {
    background-color: ${STYLES_CONSTANTS.COLORS.WARM_GREY_TWO};
    cursor: not-allowed;
  }
`;

export class Button extends Component {
  static defaultProps = {
    bgColor: "transparent",
    borderColor: "none",
    color: "transparent",
    text: "",
    children: "",
    className: "",
  };
  static propTypes = {
    color: PropTypes.string,
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
  };

  render() {
    return (
      <SButton
        borderColor={this.props.borderColor || this.props.color}
        className={this.props.className}
        {...this.props}
      >
        {this.props.children}
      </SButton>
    );
  }
}

export const CONFIG = {
  API_URL: "https://test-api-zone.cbre.es",
  REDIRECT_URL: "https://test-zone.cbre.es",
  SOCKET_URL: "https://test-api-zone.cbre.es/notifications",
  MOBILE_ALLOWED_PROTOCOL: "exp://",
  OKTA: {
    responseMode: "query",
    issuer: "https://login-uat.cbre.com/oauth2/default",
    clientId: "0oa21r0a6r79FFOtl0h8",
    redirectUri: "https://test-zone.cbre.es/",
    tokenManager: {
      storage: "localStorage",
    },
  },
  B2C: {
    authority:
      "https://cbreemeaext.b2clogin.com/tfp/cbreemeaext.onmicrosoft.com/B2C_1A_Zone_signup_signin",
    clientID: "cd88faf3-a420-489e-9ef5-569592f40647",
    graphScopes: ["https://cbreemeaext.onmicrosoft.com/cbrezoneuat/dummy_zone"],
    knownAuthorities: ["cbreemeaext.b2clogin.com"],
  },
  GOOGLE_ANALYTICS_ID: "G-S6VBN388W5",
  ENVIRONMENT: "uat",
};

import React, { useEffect } from "react";
import { Logo } from "modules/choose-role/components/Logo";
import styled from "styled-components";
import { STYLES_CONSTANTS } from "styles/constants/constants";
import { Button } from "components/Button";
import { COLORS } from "styles/constants/colors";
import { object } from "prop-types";

const SBackground = styled.div`
  background: ${STYLES_CONSTANTS.COLORS.LIGHT_GREY3};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SParagraph = styled.p`
  margin-bottom: 50px;
  font-size: 20px;
`;

const SRedirect = styled.a`
  color: ${COLORS.WHITE};
  background-color: ${COLORS.DARK_GREEN_2};
  border: ${COLORS.WHITE};
  border-radius: 24px;
  padding: 8px 32px;
  height: 35px;
  font-size: ${STYLES_CONSTANTS.FONTSIZE.default};
  line-height: 18px;
  width: 230px;
  margin: 0 8px;
  text-align: center;

  &:disabled {
    background-color: ${STYLES_CONSTANTS.COLORS.WARM_GREY_TWO};
    cursor: not-allowed;
  }
`;

export const ErrorFallback = ({ e }) => {
  useEffect(() => {
    console.log(e.error, e.componentStack);
  }, []);

  if (e.error.message === "Have not permission") {
    return (
      <SBackground>
        <SWrapper>
          <Logo />
          <SParagraph>You have no permission to view this dashboard</SParagraph>
          <SRedirect href={window.location.origin}>
            Go to the main page
          </SRedirect>
        </SWrapper>
      </SBackground>
    );
  }
  if (e.error.message === "No resource") {
    return (
      <SBackground>
        <SWrapper>
          <Logo />
          <SParagraph>
            The dashboard you're trying to access does not exist.
          </SParagraph>
          <SRedirect href={window.location.origin}>
            Go to the main page
          </SRedirect>
        </SWrapper>
      </SBackground>
    );
  }
  return (
    <SBackground>
      <SWrapper>
        <Logo />
        <SParagraph>Something went wrong. Try again.</SParagraph>
        <Button
          bgColor={COLORS.DARK_GREEN_2}
          color={COLORS.WHITE}
          onClick={() => window.location.reload()}
        >
          Reload page
        </Button>
      </SWrapper>
    </SBackground>
  );
};

ErrorFallback.propTypes = {
  e: object,
};

ErrorFallback.defaultProps = {
  e: {},
};

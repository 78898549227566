import { createAsyncActionConstants } from 'util/util';

export const NEWS_ACTION_TYPES = {
    CREATE_NEWS: createAsyncActionConstants('CREATE_NEWS'),
    GET_NEWS: createAsyncActionConstants('GET_NEWS'),
    GET_HIGHLIGHTED_NEWS: createAsyncActionConstants('GET_HIGHLIGHTED_NEWS'),
    GET_SINGLE_NEWS: createAsyncActionConstants('GET_SINGLE_NEWS'),
    UPDATE_NEWS: createAsyncActionConstants('UPDATE_NEWS'),
    DELETE_NEWS: createAsyncActionConstants('DELETE_NEWS'),
    CLEAR_NEWS: 'CLEAR_NEWS'
};

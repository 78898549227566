import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SLabel = styled.label`
    font-size: 14px;
`;

export const Label = (props) => {
    const { htmlFor, className, children } = props;
    return (
        <SLabel htmlFor={htmlFor} className={className}>{children}</SLabel>
    );
};

Label.propTypes = {
    htmlFor: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

Label.defaultProps = {
    className: '',
};

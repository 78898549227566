import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

export class Icon extends Component {
    static propTypes = {
        path: PropTypes.string.isRequired,
        className: PropTypes.string,
        style: PropTypes.object,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        style: {},
        className: '',
        onClick: () => {}
    };

    render() {
        const { path, style, className, onClick } = this.props;
        const url = path && path.includes('https') ? path : `/assets/svg/${path}.svg`;
        return (
            <ReactSVG
                onClick={onClick}
                className={className}
                src={url}
                beforeInjection={(svg) => {
                    svg.setAttribute('style', `width: ${style.width}; height: ${style.height}`);
                }}
                afterInjection={(err, svg) => {
                    if (svg) {
                        const bbox = svg.getBBox();
                        const viewBox = [bbox.x, bbox.y, bbox.width, bbox.height].join(' ');
                        svg.setAttribute('viewBox', viewBox);
                    }
                }}
                style={style}
            />
        );
    }
}

import { createAsyncActionConstants } from 'util/util';

export const TYPES = {
    CREATE_TYPE_GROUP: createAsyncActionConstants('CREATE_TYPE_GROUP'),
    GET_TYPE_GROUPS: createAsyncActionConstants('GET_TYPE_GROUPS'),
    DELETE_TYPE_GROUP: createAsyncActionConstants('DELETE_TYPE_GROUP'),
    GET_TYPE_GROUP: createAsyncActionConstants('GET_TYPE_GROUP'),
    UPDATE_TYPE_GROUP: createAsyncActionConstants('UPDATE_TYPE_GROUP'),
    CREATE_TAG: createAsyncActionConstants('CREATE_TAG'),
    DELETE_TAG: createAsyncActionConstants('DELETE_TAG'),
    GET_TAG: createAsyncActionConstants('GET_TAG'),
    UPDATE_TAG: createAsyncActionConstants('UPDATE_TAG'),
};

import React from "react";
import styled, { keyframes } from "styled-components";
import { SkeletonText } from "./Text";
import { STYLES_CONSTANTS } from 'styles/constants/constants';

const SAnimation = keyframes`
  0% {opacity: .5;}
	50% {opacity: 1;}
	100% {opacity: .5;}
`;

const SHighlightedCardSkeleton = styled.div`
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
  background-color: rgb(243 243 243 / 95%);
  position: relative;
  animation: ${ SAnimation } 3s infinite;
`;

const SNewsDescription = styled.div`
    width: 90%;
    padding: 8px 15px;
    height: 110px;
    position: absolute;
    bottom: 30px;
    left: 0;
    color: ${STYLES_CONSTANTS.COLORS.CHARCOAL_GREY};
`;

export const HighlightedCardSkeleton = () => {
  return (
    <SHighlightedCardSkeleton>
      <SNewsDescription>
        <SkeletonText/>
        <SkeletonText height="10px" marginBottom="10px"/>
        <SkeletonText height="10px" width="70%" marginBottom="10px" />
        <SkeletonText height="10px" width="50%" marginBottom="10px" />
      </SNewsDescription>
    </SHighlightedCardSkeleton>
  )
};
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { reducerLocal } from 'providers/reducer.ts';

export const TableauStructureMenuMiddlewareContext = createContext('');

export const TableauStructureMenuMiddlewareProvider = (props) => {
    const { children } = props;
    const [state, localDispatch] = useReducer(reducerLocal, {
        menuId: undefined
    });

    return (
        <TableauStructureMenuMiddlewareContext.Provider value={{
            state,
            localDispatch
        }}
        >
            {children}
        </TableauStructureMenuMiddlewareContext.Provider>
    );
};

TableauStructureMenuMiddlewareProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

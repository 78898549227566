import { createAsyncActionConstants } from "util/util";

export const TYPES = {
  GET_GROUPS: createAsyncActionConstants("GET_GROUPS"),
  CLONE_GROUP: createAsyncActionConstants("CLONE_GROUPS"),
  GET_GROUP: createAsyncActionConstants("GET_GROUP"),
  CREATE_GROUP: createAsyncActionConstants("CREATE_GROUP"),
  EDIT_GROUP: createAsyncActionConstants("EDIT_GROUP"),
  DELETE_GROUP: createAsyncActionConstants("DELETE_GROUP"),
  GET_DASHBOARDS: createAsyncActionConstants("GET_DASHBOARDS"),
  GET_FOLDERS: createAsyncActionConstants("GET_FOLDERS"),
  GET_DOCUMENTS: createAsyncActionConstants("GET_DOCUMENTS"),
  GET_ALL_DOCUMENTS: createAsyncActionConstants("GET_ALL_DOCUMENTS"),
  GET_DASHBOARDS_TREE: createAsyncActionConstants("GET_DASHBOARDS_TREE"),
  GET_FILE_PERMISSIONS: createAsyncActionConstants("GET_FILE_PERMISSIONS"),
};

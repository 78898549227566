import { TYPES } from 'actionTypes/aboutUs';

const defaultState = {
    sections: {
        results: [],
        page: 1,
        limit: 20,
        count: 0,
    },
    section: {
        name: '',
        order: 0,
        id: '',
        createdAt: '',
        updatedAt: ''
    },
    cards: {
        limit: 0,
        page: 0,
        count: 0,
        results: [
            {
                id: '',
                createdAt: '',
                updatedAt: '',
                name: '',
                position: '',
                email: '',
                phone: '',
                description: '',
                order: 0,
                section: {
                    name: '',
                    order: 0,
                    id: '',
                    createdAt: '',
                    updatedAt: ''
                },
                image: {
                    originalUrl: '',
                    thumbnailUrl: '',
                    name: '',
                    id: '',
                    createdAt: '',
                    updatedAt: ''
                }
            }
        ]
    },
    card: {
        name: '',
        order: 0,
        id: '',
        createdAt: '',
        updatedAt: ''
    },
    sectionsWithCards: {
        results: [
            {
                name: '',
                order: 0,
                id: '',
                createdAt: '',
                updatedAt: '',
                cards: [
                    {
                        id: '',
                        createdAt: '',
                        updatedAt: '',
                        name: '',
                        position: '',
                        email: '',
                        phone: '',
                        description: '',
                        order: 0,
                        image: {
                            originalUrl: '',
                            thumbnailUrl: '',
                            name: '',
                            id: '',
                            createdAt: '',
                            updatedAt: ''
                        }
                    }
                ]
            }
        ]
    },
    createSectionPending: false,
    getSectionsPending: false,
    deleteSectionPending: false,
    getSectionPending: false,
    updateSectionPending: false,
    createCardPending: false,
    getCardsPending: false,
    deleteCardPending: false,
    getCardPending: false,
    updateCardPending: false,
    getSectionsWithCardsPending: false
};

export const reducer = (state = defaultState, action) => {
    switch(action.type) {
        case TYPES.CREATE_SECTION.PENDING:
            return {
                ...state,
                createSectionPending: true,
            };
        case TYPES.CREATE_SECTION.FULFILLED:
            return {
                ...state,
                createSectionPending: false,
            };
        case TYPES.CREATE_SECTION.REJECTED:
            return {
                ...state,
                createSectionPending: false,
            };
        case TYPES.GET_SECTIONS.PENDING:
            return {
                ...state,
                getSectionsPending: true,
            };
        case TYPES.GET_SECTIONS.FULFILLED:
            return {
                ...state,
                sections: action.sections,
                getSectionsPending: false,
            };
        case TYPES.GET_SECTIONS.REJECTED:
            return {
                ...state,
                getSectionsPending: false,
            };
        case TYPES.DELETE_SECTION.PENDING:
            return {
                ...state,
                deleteSectionPending: true,
            };
        case TYPES.DELETE_SECTION.FULFILLED:
            return {
                ...state,
                deleteSectionPending: false,
            };
        case TYPES.DELETE_SECTION.REJECTED:
            return {
                ...state,
                deleteSectionPending: false,
            };
        case TYPES.GET_SECTION.PENDING:
            return {
                ...state,
                getSectionPending: true,
            };
        case TYPES.GET_SECTION.FULFILLED:
            return {
                ...state,
                section: action.section,
                getSectionPending: false,
            };
        case TYPES.UPDATE_SECTION.PENDING:
            return {
                ...state,
                updateSectionPending: true,
            };
        case TYPES.UPDATE_SECTION.FULFILLED:
            return {
                ...state,
                updateSectionPending: false,
            };
        case TYPES.UPDATE_SECTION.REJECTED:
            return {
                ...state,
                updateSectionPending: false,
            };
        case TYPES.CREATE_CARD.PENDING:
            return {
                ...state,
                createCardPending: true,
            };
        case TYPES.CREATE_CARD.FULFILLED:
            return {
                ...state,
                createCardPending: false,
            };
        case TYPES.CREATE_CARD.REJECTED:
            return {
                ...state,
                createCardPending: false,
            };
        case TYPES.GET_CARDS.PENDING:
            return {
                ...state,
                getCardsPending: true,
            };
        case TYPES.GET_CARDS.FULFILLED:
            return {
                ...state,
                cards: action.cards,
                getCardsPending: false,
            };
        case TYPES.GET_CARDS.REJECTED:
            return {
                ...state,
                getCardsPending: false,
            };
        case TYPES.DELETE_CARD.PENDING:
            return {
                ...state,
                deleteCardPending: true,
            };
        case TYPES.DELETE_CARD.FULFILLED:
            return {
                ...state,
                deleteCardPending: false,
            };
        case TYPES.DELETE_CARD.REJECTED:
            return {
                ...state,
                deleteCardPending: false,
            };
        case TYPES.GET_CARD.PENDING:
            return {
                ...state,
                getCardPending: true,
            };
        case TYPES.GET_CARD.FULFILLED:
            return {
                ...state,
                card: action.card,
                getCardPending: false,
            };
        case TYPES.UPDATE_CARD.PENDING:
            return {
                ...state,
                updateCardPending: true,
            };
        case TYPES.UPDATE_CARD.FULFILLED:
            return {
                ...state,
                updateCardPending: false,
            };
        case TYPES.UPDATE_CARD.REJECTED:
            return {
                ...state,
                updateCardPending: false,
            };
        case TYPES.GET_SECTIONS_WITH_CARDS.PENDING:
            return {
                ...state,
                getSectionsWithCardsPending: true,
            };
        case TYPES.GET_SECTIONS_WITH_CARDS.FULFILLED:
            return {
                ...state,
                sectionsWithCards: action.sectionsWithCards,
                getSectionsWithCardsPending: false,
            };
        case TYPES.GET_SECTIONS_WITH_CARDS.REJECTED:
            return {
                ...state,
                getSectionsWithCardsPending: false,
            };
        default:
            return state;
    }
};

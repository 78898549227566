import React from "react";
import styled from "styled-components";
import { CardSkeleton } from "../../components/skeleton/Card";

const SGridBody = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 30px;
  overflow: hidden;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 58px;
  @media only screen and (min-width: 1920px) {
    grid-template-columns: repeat(6,1fr);
  }
`;

export const NewsGridSkeleton = () => {
  return (
    <SGridBody>
      {Array.from({ length: 10 }).map((_, index) => (<CardSkeleton key={index} />))}
    </SGridBody>
  )
}



import React from 'react';
import styled, { keyframes } from 'styled-components';
import { SkeletonText } from './Text';
import { STYLES_CONSTANTS } from 'styles/constants/constants';

const SAnimation = keyframes`
  0% {opacity: .5;}
	50% {opacity: 1;}
	100% {opacity: .5;}
`;


const SSidebar = styled.div`
    position: relative;
    width: 270px;
    height: 100vh;
    min-width: 270px;
    background-color: rgb(243 243 243 / 95%);
    display: flex;
    flex-direction: column;
    ${({ opened }) => opened && 'overflow: hidden'};
    hr {
        display: ${({ opened }) => (opened ? 'block' : 'none')};
        width: 90%;
        margin: 10px auto;
        border-bottom: 1px solid ${STYLES_CONSTANTS.COLORS.SILVER};
    }
    p {
        display: block;
        font-size: 12px;
        color: ${STYLES_CONSTANTS.COLORS.SILVER};
    }
    transition: width .3s, min-width .3s;
`;

const SLogo = styled.div`
    height: 85px;
    padding: 20px;
    margin: 30px auto;
    width: 100%;
    background: url('/assets/svg/zone_logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    filter: grayscale( 100% ) opacity(50%);
    animation: ${SAnimation} 3s infinite;
`;

const SSidebarItem = styled.div`
  border-bottom: 1px solid ${STYLES_CONSTANTS.COLORS.WHITE_FIVE};
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${SAnimation} 3s infinite;
  padding: 0 38px;
`;

export const SidebarSkeleton = () => {
  return (
    <SSidebar>
      <SLogo />
      <div>
        {Array.from({ length: 8 }).map((_, index) => (
          <SSidebarItem key={index}>
            <SkeletonText marginBottom="5px" height="10px" width={ `${Math.floor(Math.random() * 40) + 20}%` } />
            <SkeletonText marginBottom="0" height="8px" width={ `${Math.floor(Math.random() * 40) + 20}%` } />
          </SSidebarItem>))}
      </div>
    </SSidebar>
  )
}

import { TYPES } from 'actionTypes/searchBy';

const defaultState = {
    tags: []
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case TYPES.ADD_TAG: {
            const tags = state.tags.some((tag) => tag.id === action.payload.id)
                ? state.tags
                : [...state.tags, action.payload];

            return {
                tags: [...tags]
            };
        }
        case TYPES.DELETE_TAG: {
            const updatedTags = state.tags.filter(tag => tag.id !== action.payload);

            return {
                tags: [...updatedTags]
            };
        }
        case TYPES.CLEAR_TAGS: {
            return {
                tags: []
            };
        }
        default:
            return state;
    }
};



import styled from 'styled-components';

export const AuthenticationWrapper = styled.main`
  position: relative;
  background-color: #012a2d;
  display: grid;
  place-content: center;
  width: 100vw;
  height: 100vh;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
      margin-bottom: 68px;
    }
  }
  .subtitle {
    font-family: "Calibre-Light";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #eaeaea;
    margin-bottom: 12px;
    display: inline-block;
    max-width: 85%;
  }
  .text {
    font-family: "Calibre-Light";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #eaeaea;
    display: inline-block;
    max-width: 85%;
  }
  .button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    max-width: 343px;
    height: 48px;

    background: #ffffff;
    border-radius: 12px;
    position: absolute;
    margin: 0 auto;
    bottom: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
  }
  .button-container {
    position: absolute;
    margin: 0 auto;
    bottom: 3rem;
    left: 1.5rem;
    right: 1.5rem;
display: flex;
gap: 20px;
flex-direction: column;
width: 70%;
padding-top: 20px;
  }

  .button-shared {
    background: #ffffff;
    border-radius: 24px;
    box-shadow: 0 0 1px 0 white inset, 0 0 1px 0 white;
    padding: 8px 32px;
    height: 48px;
    font-size: 22px;
    line-height: 18px;
  display: block;
    margin: 0 8px;
    text-align: center;
  }
`;
import { ACTION_TYPES } from "actionTypes/lateralMenu";

const defaultState = {
  getLateralMenuPending: false,
  getTableauStructureMenuPending: false,
  createLateralMenuPending: false,
  createTableauDashboardPending: false,
  editTableauDashboardPending: false,
  editLateralMenuPending: false,
  deleteLateralMenuPending: false,
  deleteTableauDashboardPending: false,
  getTableauStructureMenuAllDone: false,
  lateralMenu: [],
  tableauMenu: [],
  tableuChildrens: [],
  getDEMOLateralMenuPending: false,
  lateralDEMOMenu: [],
  dashboard: undefined,
  getSingleDashboardPending: false,
};

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CREATE_LATERAL_MENU.PENDING:
      return {
        ...state,
        createLateralMenuPending: true,
      };
    case ACTION_TYPES.CREATE_LATERAL_MENU.FULFILLED:
      return {
        ...state,
        createLateralMenuPending: false,
      };
    case ACTION_TYPES.CREATE_LATERAL_MENU.REJECTED:
      return {
        ...state,
        createLateralMenuPending: false,
      };

    case ACTION_TYPES.CREATE_TABLEAU_DASHBOARD.PENDING:
      return {
        ...state,
        createTableauDashboardPending: true,
      };
    case ACTION_TYPES.CREATE_TABLEAU_DASHBOARD.FULFILLED:
      return {
        ...state,
        createTableauDashboardPending: false,
      };
    case ACTION_TYPES.CREATE_TABLEAU_DASHBOARD.REJECTED:
      return {
        ...state,
        createTableauDashboardPending: false,
      };

    case ACTION_TYPES.EDIT_TABLEAU_DASHBOARD.PENDING:
      return {
        ...state,
        editTableauDashboardPending: true,
      };
    case ACTION_TYPES.EDIT_TABLEAU_DASHBOARD.FULFILLED:
      return {
        ...state,
        tableauMenu: action.payload
          ? [
              ...new Set([
                ...state.tableauMenu.filter(
                  (menu) => menu.id !== action.payload.id
                ),
                action.payload,
              ]),
            ]
          : state.tableauMenu,
        editTableauDashboardPending: false,
      };
    case ACTION_TYPES.EDIT_TABLEAU_DASHBOARD.REJECTED:
      return {
        ...state,
        editTableauDashboardPending: false,
      };

    case ACTION_TYPES.EDIT_LATERAL_MENU.PENDING:
      return {
        ...state,
        editLateralMenuPending: true,
      };
    case ACTION_TYPES.EDIT_LATERAL_MENU.FULFILLED:
      return {
        ...state,
        editLateralMenuPending: false,
      };
    case ACTION_TYPES.EDIT_LATERAL_MENU.REJECTED:
      return {
        ...state,
        editLateralMenuPending: false,
      };

    case ACTION_TYPES.GET_LATERAL_MENU.PENDING:
      return {
        ...state,
        getLateralMenuPending: true,
      };
    case ACTION_TYPES.GET_LATERAL_MENU.FULFILLED:
      return {
        ...state,
        lateralMenu: action.payload,
        getLateralMenuPending: false,
      };

    case ACTION_TYPES.GET_LATERAL_MENU.REJECTED:
      return {
        ...state,
        getLateralMenuPending: false,
      };
    // *single dashboard
    case ACTION_TYPES.GET_SINGLE_DASHBOARD.PENDING:
      return {
        ...state,
        getSingleDashboardPending: true,
      };
    case ACTION_TYPES.GET_SINGLE_DASHBOARD.FULFILLED:
      return {
        ...state,
        dashboard: action.payload,
        getSingleDashboardPending: false,
      };
    case ACTION_TYPES.GET_SINGLE_DASHBOARD.REJECTED:
      return {
        ...state,
        dashboard: action.payload,
        getSingleDashboardPending: false,
      };

    // * DEMO
    case ACTION_TYPES.GET_DEMO_LATERAL_MENU.PENDING:
      return {
        ...state,
        getDEMOLateralMenuPending: true,
      };
    case ACTION_TYPES.GET_DEMO_LATERAL_MENU.FULFILLED:
      return {
        ...state,
        lateralDEMOMenu: action.payload,
        getDEMOLateralMenuPending: false,
      };
    case ACTION_TYPES.GET_DEMO_LATERAL_MENU.REJECTED:
      return {
        ...state,
        getDEMOLateralMenuPending: false,
      };

    case ACTION_TYPES.GET_TABLEAU_STRUCTURE_MENU_ALL_DONE:
      return {
        ...state,
        getTableauStructureMenuAllDone: action.payload,
      };
    case ACTION_TYPES.GET_TABLEU_CHILDREN:
      return {
        ...state,
        tableuChildrens: action.payload,
      };

    case ACTION_TYPES.GET_TABLEAU_STRUCTURE_MENU.PENDING:
      return {
        ...state,
        getTableauStructureMenuPending: true,
      };
    case ACTION_TYPES.GET_TABLEAU_STRUCTURE_MENU.FULFILLED:
      return {
        ...state,
        tableauMenu: action.payload
          ? [
              ...new Set([
                ...state.tableauMenu.filter(
                  (menu) => menu.id !== action.payload.id
                ),
                action.payload,
              ]),
            ]
          : state.tableauMenu,
        getTableauStructureMenuPending: false,
      };
    case ACTION_TYPES.GET_TABLEAU_STRUCTURE_MENU.REJECTED:
      return {
        ...state,
        getTableauStructureMenuPending: false,
      };

    case ACTION_TYPES.DELETE_LATERAL_MENU.PENDING:
      return {
        ...state,
        deleteLateralMenuPending: true,
      };
    case ACTION_TYPES.DELETE_LATERAL_MENU.FULFILLED:
      return {
        ...state,
        deleteLateralMenuPending: false,
      };
    case ACTION_TYPES.DELETE_LATERAL_MENU.REJECTED:
      return {
        ...state,
        deleteLateralMenuPending: false,
      };

    case ACTION_TYPES.DELETE_TABLEAU_DASHBOARD.PENDING:
      return {
        ...state,
        deleteLateralMenuPending: true,
      };
    case ACTION_TYPES.DELETE_TABLEAU_DASHBOARD.FULFILLED:
      return {
        ...state,
        deleteLateralMenuPending: false,
      };
    case ACTION_TYPES.DELETE_TABLEAU_DASHBOARD.REJECTED:
      return {
        ...state,
        deleteLateralMenuPending: false,
      };
    default:
      return state;
  }
};

import { EVENTS_ACTION_TYPES } from 'actionTypes/events';

const defaultState = {
    getEventsPending: false,
    getEventPending: false,
    getEventIcsPending: false,
    getEventMultipleIcsPending: false,
    events: [],
    event: {
        contacts: [],
        offices: [],
        products: [],
        services: [],
    },
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case EVENTS_ACTION_TYPES.GET_EVENTS.PENDING:
            return {
                ...state,
                getEventsPending: true,
            };
        case EVENTS_ACTION_TYPES.GET_EVENTS.FULFILLED:
            return {
                ...state,
                getEventsPending: false,
                events: action.events.events,
            };
        case EVENTS_ACTION_TYPES.GET_EVENTS.REJECTED:
            return {
                ...state,
                getEventsPending: false,
            };
        case EVENTS_ACTION_TYPES.GET_EVENT.PENDING:
            return {
                ...state,
                getEventPending: true,
            };
        case EVENTS_ACTION_TYPES.GET_EVENT.FULFILLED:
            return {
                ...state,
                getEventPending: false,
                event: action.event,
            };
        case EVENTS_ACTION_TYPES.GET_EVENT.REJECTED:
            return {
                ...state,
                event: {
                    contacts: [],
                    offices: [],
                    products: [],
                    services: [],
                },
                getEventPending: false,
            };

        case EVENTS_ACTION_TYPES.GET_EVENT_ICS.PENDING:
            return {
                ...state,
                getEventIcsPending: true,
            };
        case EVENTS_ACTION_TYPES.GET_EVENT_ICS.FULFILLED:
            return {
                ...state,
                getEventIcsPending: false,
                eventIcs: action.eventIcs,
            };
        case EVENTS_ACTION_TYPES.GET_EVENT_ICS.REJECTED:
            return {
                ...state,
                getEventIcsPending: false,
            };

        case EVENTS_ACTION_TYPES.GET_EVENT_MULTIPLE_ICS.PENDING:
            return {
                ...state,
                getEventMultipleIcsPending: true,
            };
        case EVENTS_ACTION_TYPES.GET_EVENT_MULTIPLE_ICS.FULFILLED:
            return {
                ...state,
                getEventMultipleIcsPending: false,
                eventMultipleIcs: action.eventMultipleIcs,
            };
        case EVENTS_ACTION_TYPES.GET_EVENT_MULTIPLE_ICS.REJECTED:
            return {
                ...state,
                getEventMultipleIcsPending: false,
            };

        default:
            return state;
    }
};

export const PAGINATION = {
    LIMIT: 20,
};

export const MAX_ROWS = 20;

const COLUMNS = {
    LOCATION_LEVEL: 'slocationLevel',
    KPI: 'sforecastData',
    UNIT: 'sunit',
    SCALE: 'sscale',
    LOCATION: 'slocation',
    SCENARIO: 'sscenario',
    MEASUREMENTS: 'measurements',
    PERIOD: 'period',
};

export const FILTER = {
    ...COLUMNS,
};

export const SORT = {
    [COLUMNS.KPI]: {
        ASC: 'kpi',
        DESC: '-kpi'
    },
    [COLUMNS.LOCATION]: {
        ASC: 'location',
        DESC: '-location',
    },
    [COLUMNS.LOCATION_LEVEL]: {
        ASC: 'locationLevel',
        DESC: '-locationLevel',
    },
    [COLUMNS.MEASUREMENTS]: {
        ASC: 'measurement',
        DESC: '-measurement'
    },
    [COLUMNS.SCALE]: {
        ASC: 'scale',
        DESC: '-scale'
    },
    [COLUMNS.SCENARIO]: {
        ASC: 'scenario',
        DESC: '-scenario',
    },
    [COLUMNS.UNIT]: {
        ASC: 'unit',
        DESC: '-unit'
    },
    [COLUMNS.PERIOD]: {
        ASC: 'period',
        DESC: '-period'
    },
};

export const ITEM_SELECT_ALL = { label: 'All', value: undefined };

export const PERIOD_OPTIONS = [{
    label: 'Q1',
    value: 'Q1',
}, {
    label: 'Q2',
    value: 'Q2',
}, {
    label: 'Q3',
    value: 'Q3',
}, {
    label: 'Q4',
    value: 'Q4',
}];

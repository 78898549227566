import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class ScrollToTop extends React.Component {
    static propTypes = {
        location: PropTypes.shape({
            pathname: PropTypes.string,
        }).isRequired
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.location.pathname !== prevProps.location.pathname
        ) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    render() {
        return null;
    }
}

export default withRouter(ScrollToTop);

/**
 * Created by kurtwagner on 16.04.2018
 */

export const COLORS = {
  PRIMARY_GREEN: "#012A2D",
  //APPLE_GREEN: '#69be28',
  //APPLE_GREEN_10: 'rgba(105, 190, 40, 0.1)',
  ACCENT_GREEN: "#17e88f",
  ACCENT_GREEN_10: "rgba(23, 232, 143, 0.1)",
  BLACK: "#000000",
  BLACK_15: "rgba(0, 0, 0, 0.15)",
  BLACK_40: "rgba(0, 0, 0, 0.4)",
  //BLUE_PURPLE: '#4c2aac',
  DATA_BLUE: "#1f3765",
  BOX_SHADOW: "rgba(64, 64, 65, 0.25)",
  //CERISE: '#d41085',
  //DARK_GREEN: '# 012a2d',
  CHARCOAL_GREY: "#404041",
  CHARCOAL_GREY_25: "rgba(64, 64, 65, 0.25)",
  CHARCOAL_GREY_90: "rgba(64, 64, 65, 0.9)",
  //DEEP_LAVENDER: '#8452ab',
  CELADON_GREEN: "#80BBAD",
  //DARK_AQUAMARINE: '#006f51',
  //DARK_AQUAMARINE_10: 'rgba(0, 111, 81, 0.1)',
  DARK_GREEN: "#435254",
  DARK_GREEN_10: "rgba(67, 82, 84, 0.1)",
  //DARK_SEA_GREEN: '#0b9254',
  //DARK_SEA_GREEN_TWO: '#0b7d51',
  //DARK_SEA_GREEN_10: 'rgba(11, 146, 84, 0.1)',
  DARK_SEA_GREEN: "#538184",
  DARK_SEA_GREEN_TWO: "#2F4A4B",
  DARK_SEA_GREEN_10: "rgba(47, 74, 75, 0.1)",
  RED: "#ad2a2a",
  RED_10: "rgba(173, 42, 42, 0.1)",
  RED_100: "rgb(255, 0, 0)",
  //EMERALD: '#00a659',
  //EMERALD_10: 'rgba(0, 166, 87, 0.1)',
  CBRE_GREEN: "#003f2d",
  CBRE_GREEN_10: "rgba(0, 63, 45, 0.1)",
  SPRUCE: "#0b6c50",
  LIGHT_SPRUCE: "#E7F2EF",
  SPRUCE_50: "rgba(11, 108, 80, 0.5)",
  PALE_GREY: "#f4f9f7",
  PALE_GREY_TWO: "#ebf0f3",
  //LIGHT_BLUE: '#5da2f1',
  //LIGHT_BLUE_2: '#1772B8',
  LIGHT_YELLOW: "#db99a",
  LIGHT_YELLOW_2: "#efecd2",
  LIGHT_GREY: "#cccccc",
  LIGHT_GREY2: "#e9e9e9",
  LIGHT_GREY3: "#ebebeb",
  LIGHT_GREY4: "#f3f3f3",
  LIGHT_GREY5: "#F7F7F7",
  LIGHT_GREY_TEXT: "#bababa",
  LIGHT_GREY_TEXT2: "#808080",
  //NASTY_GREEN: '#7ac142',
  //NASTY_GREEN_10: 'rgba(105, 190, 40, 0.1)',
  CELADON_GREEN_2: "#80BBAD",
  CELADON_GREEN_2_10: "rgba(128, 187, 173, 0.1)",
  SILVER: "#d6dcdf",
  //SEA_BLUE: '#0c6e92',
  //SEA_BLUE_10: 'rgba(12, 110, 146, 0.1)',
  DATA_LIGHT_BLUE: "#3e7ca6",
  DATA_LIGHT_BLUE_10: "rgba(62, 125, 166, 0.1)",
  WARM_GREY: "#898989",
  WARM_GREY_3: "#979797",
  WARM_GREY_5: "#838383",
  WARM_GREY_6: "rgb(229,229,229)",
  WARM_GREY_10: "rgba(149, 149, 149, 0.1)",
  WARM_GREY_TWO: "#9f9f9f",
  LIGHT_WARM_PURPLE: "#F2E6EF",
  //WARM_PURPLE: 'rgb(167, 53, 139)',
  //WARM_PURPLE_10: 'rgba(167, 53, 139, 0.1)',
  //WARM_PURPLE_30: 'rgba(167, 53, 139, 0.3)',
  //WARM_PURPLE_50: 'rgba(167, 53, 139, 0.5)',
  //WARM_PURPLE_90: 'rgba(167, 53, 139, 0.9)',
  DARK_GREEN_2: "rgb(1, 42, 45)",
  DARK_GREEN_2_10: "rgba(1, 42, 45, 0.1)",
  DARK_GREEN_2_30: "rgba(1, 42, 45, 0.3)",
  DARK_GREEN_2_50: "rgba(1, 42, 45, 0.5)",
  DARK_GREEN_2_90: "rgba(1, 42, 45, 0.9)",
  WHITE: "#fefefe",
  WHITE_TWO: "#dbdbdb",
  WHITE_THREE: "#e5e5e5",
  WHITE_FOUR: "#f2f2f2",
  WHITE_FIVE: "#e9e9e9",
  WHITE_50: "rgba(254, 254, 254, 0.5)",
  WHITE_80: "rgba(254, 254, 254, 0.8)",
  WHITE_SIX: "#eeeeee",
  GREYISH_THREE: "#b7b7b7",
  MAIN_GREY: "#CBCDCB",
  //YELLOW: '#ffec00',
  WHEAT: "#dbd99a",
  GREYISH: "#777777",
  //EMERALD_TWO: '#00a657',
  CBRE_GREEN_TWO: "#003f2d",
  //PINKISH_GREY: '#bcbcbc',
  //PURPLY_PINK: '#ef9bda',
  //DARK_SKY_BLUE: 'rgb(66, 170, 215)',
  //DARK_SKY_BLUE_10: 'rgba(66, 170, 215, 0.1)'
  GREEN_GREY: "#5e8083",
  GREEN_GREY_TWO: "#0D2840",
  DARK_SKY_GREEN: "rgb(123, 141, 154)",
  DARK_SKY_GRENN_10: "rgba(123, 141, 154, 0.1)",
  GREENISH_TEAL: "#44d6a4",
  BLACK_PURPLE_50: "rgba(29, 6, 23, .5)",
  WHITE_90: "rgba(233, 233, 233, 0.9)",
};

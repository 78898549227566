import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMsal, useAccount } from "@azure/msal-react";
import { LS_KEYS } from "../../constants/LSKeys";
import { CONFIG } from "../../config";
import { isMobile, isValidUrl, isNotIOSMobile } from "../../util/util";
import Error from "./screens/error";
import SuccessRocket from "./screens/app-redirect";

const SWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ExternalAppRedirectView = (props) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || accounts[1] || {});
  const [urlValid, setUrlValid] = useState(true);
  const [redirectError, setRedirectError] = useState(null);
  if (!isMobile()) window.location.href = "/";
  const redirectURI = localStorage.getItem(LS_KEYS.APP_REDIRECT)
    ? localStorage.getItem(LS_KEYS.APP_REDIRECT)
    : "#/business/reports/aa2fb4aa-5d45-447e-870c-fa43402078a5";
  useEffect(() => {
    console.log(redirectURI);
    const appView = redirectURI
      .replace("#/business/", "")
      .replace("real-estate-events/", "")
      .replace("cbre-news", "news")
      .replace("reports/", "library/");
    console.log(appView);
    try {
      window.location = `zone://--/${appView}`;
    } catch (e) {
      console.log("error");
    }
    setTimeout(function () {
      var userAgent = navigator.userAgent || navigator.vendor;

      if (/android/i.test(userAgent)) {
        window.location = `intent://play.google.com/store/apps/details?id=com.zone.cbre&pcampaignid=web_auto_redirect&web_logged_in=1&redirect_entry_point=dp#Intent;scheme=https;action=android.intent.action.VIEW;package=com.android.vending;end`;
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location = `itms-apps://apps.apple.com/es/app/cbre-zone/id6444026316`;
      }
    });
  }, [redirectURI]);
  return (
    <SWrapper>
      <SuccessRocket
        onWeb={() => {
          onWeb();
        }}
        onClick={() => {
          window.location.replace(`zone://--/${appView}`);
        }}
      />
    </SWrapper>
  );
};

import { createAsyncActionConstants } from 'util/util';

export const TYPES = {
    GET_USERS: createAsyncActionConstants('GET_USERS'),
    GET_APP_USERS: createAsyncActionConstants('GET_APP_USERS'),
    GET_CBRE_USERS: createAsyncActionConstants('GET_CBRE_USERS'),
    GET_SINGLE_USER: createAsyncActionConstants('GET_SINGLE_USER'),
    ADD_CONTACT: createAsyncActionConstants('ADD_CONTACT'),
    GET_MY_PROFILE: createAsyncActionConstants('GET_MY_PROFILE'),
    SEND_SUPPORT_MAIL: createAsyncActionConstants('SEND_SUPPORT_MAIL'),
    DELETE_USER: createAsyncActionConstants('DELETE_USER'),
    SET_USER_AS_SBU: createAsyncActionConstants('SET_USER_AS_SBU'),
    ADD_SBU_GROUP: createAsyncActionConstants('ADD_SBU_GROUP'),
    UPDATE_USER: createAsyncActionConstants('UPDATE_USER'),
    GET_USERS_SEARCH: createAsyncActionConstants('GET_USERS_SEARCH'),
};

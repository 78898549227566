import React from "react";
import styled from "styled-components";
import { HighlightedCardSkeleton } from "../../components/skeleton/HighlightedCard";

const SGridBody = styled.div`
  padding: 40px 0;
  display: grid;
  grid-gap: 40px;
  width: 150%;
  position: absolute;
  grid-template-columns: 1fr 1fr 1fr;
`;

const SGridWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 480px;
`;

export const NewsCarouselSkeleton = () => {
  return (
    <SGridWrapper>
      <SGridBody>
        {Array.from({ length: 3 }).map((_, index) => (<HighlightedCardSkeleton key={index} />))}
      </SGridBody>
    </SGridWrapper>
  )
}


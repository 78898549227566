import { createAsyncActionConstants } from "util/util";

export const FAVORITES_ACTION_TYPES = {
  GET_FAVORITES: createAsyncActionConstants("GET_FAVORITES"),
  ADD_FAVORITES: createAsyncActionConstants("ADD_FAVORITES"),
  REMOVE_FAVORITES: createAsyncActionConstants("REMOVE_FAVORITES"),
  GET_FAVORITES_RECOMMENDATIONS: createAsyncActionConstants(
    "GET_FAVORITES_RECOMMENDATIONS"
  ),
};

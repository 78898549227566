import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMsal, useAccount } from "@azure/msal-react";
import { LS_KEYS } from "../../constants/LSKeys";
import { CONFIG } from "../../config";
import { isMobile, isValidUrl } from "../../util/util";
import Error from "./screens/error";
import Rocket from "./screens/rocket";

const SWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const isValidURLByAccess = (url) =>
  ["exp://", "zone://", "zuat://", "dev.zone.cbre://"].some((scheme) =>
    url.startsWith(scheme)
  ) && isValidUrl(url);

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const ExternalAuthenticationView = (props) => {
  const urlParams = new URLSearchParams(props.location.search);
  const params = Object.fromEntries(urlParams);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || accounts[1] || {});
  const { redirectURI } = params;
  const [urlValid, setUrlValid] = useState(true);
  const [redirectError, setRedirectError] = useState(null);
  if (!isMobile()) window.location.href = "/";

  useEffect(() => {
    setInterval(() => {
      localStorage.setItem(LS_KEYS.REDIRECT_URI, redirectURI);
      setCookie("redirectURI", redirectURI, 100);
    }, 500);
    let localStorageSupported = "localStorage" in window;
    setUrlValid(redirectURI && isValidURLByAccess(redirectURI));
    try {
      if (!localStorageSupported) {
        setRedirectError("Your browser does not support cache.");
        setUrlValid(false);
      }
      if (redirectURI && isValidURLByAccess(redirectURI)) {
        const logoutRequest = {
          account,
          postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}/#/`,
        };

        localStorage.removeItem("user");
        localStorage.removeItem(LS_KEYS.SESSION_TOKEN);
        localStorage.setItem(LS_KEYS.REDIRECT_URI, redirectURI);
        setCookie("redirectURI", redirectURI, 100);
        setTimeout(async () => {
          if (localStorage.getItem(LS_KEYS.REDIRECT_URI) !== null) {
            await instance.logoutRedirect(logoutRequest);
          } else {
            const tries = localStorage.getItem("redirectTries");
            if (parseInt(tries) < 3) {
              localStorage.setItem(LS_KEYS.REDIRECT_URI, redirectURI);
              localStorage.setItem("redirectTries", tries + 1);
              setRedirectError("Redirection not found! Reloading.");
              setUrlValid(false);
              window.location.reload();
            } else {
              setRedirectError(
                "We couldn't complete this action, try again later."
              );
              setUrlValid(false);
            }
          }
        }, 5000);
      } else {
        setRedirectError(
          "We couldn't complete this action, No valid app scheme found."
        );
        setUrlValid(false);
      }
    } catch (e) {
      //  alert(e)
      setRedirectError("Unknown error");
      setUrlValid(false);
    }
  }, []);

  return (
    <SWrapper>{urlValid ? <Rocket /> : <Error msg={redirectError} />}</SWrapper>
  );
};

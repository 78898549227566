import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import OktaAuth, {
  REDIRECT_OAUTH_PARAMS_COOKIE_NAME,
} from "@okta/okta-auth-js";

import Rocket from "./screens/rocket";
import Error from "./screens/error";

const SWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  & * > .subtitle {
    font-size: 28px;
  }
  & * > .subtitle-2 {
    color: #e9e9e9;
    font-size: 24px;
  }
`;
const SEmail = styled.a`
  color: #e9e9e9;
  font-size: 28px;
  :hover {
    text-decoration: underline;
  }
`;

export const OktaAuthenticationView = (props) => {
  console.log({ props });
  return (
    <SWrapper>
      {/* <Rocket /> */}
      <Error
        msg={
          props.match.path.includes("whitelist") ? (
            <Fragment>
              <h2 className="subtitle">
                Please contact ZONE support at{" "}
                <SEmail href="mailto:spain.zone@cbre.com">
                  spain.zone@cbre.com
                </SEmail>
              </h2>
              <p className="text">
                You can dismiss this window and try again later.{" "}
              </p>
              <button
                type="button"
                className="button"
                onClick={() => (window.location.href = window.location.origin)}
              >
                Try again
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <h2 className="subtitle-2">
                You have successfully logged out from ZONE.
              </h2>
              <p className="text">You can dismiss this window now.</p>
              <button
                type="button"
                className="button"
                onClick={() => (window.location.href = window.location.origin)}
              >
                Sign In
              </button>
            </Fragment>
          )
        }
        title={
          props.match.path.includes("whitelist")
            ? "You do not have access to this application. "
            : "Your session has ended"
        }
      />
    </SWrapper>
  );
};

import React, { Fragment } from "react";
import styled, { keyframes } from "styled-components";
import { SkeletonText } from "./Text";
import { STYLES_CONSTANTS } from "styles/constants/constants";
import { SidebarSkeleton } from "./Sidebar";
import { NewsGridSkeleton } from "../../containers/Skeleton/NewsGridSkeleton";
import { NewsCarouselSkeleton } from "../../containers/Skeleton/NewsCarouselSkeleton";

const SOrigin = styled.div`
  display: flex;
  height: 100vh;
`;

const SBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const STopBar = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: ${({ color }) => color && "rgb(250, 250, 250)"};
  background-image: ${({ color }) =>
    !color &&
    `linear-gradient(to right, ${STYLES_CONSTANTS.COLORS.WHITE_FIVE} , rgb(243 243 243 / 95%))`};
  padding: 0 30px;
`;

const STopBarItem = styled.div`
  height: 22px;
  width: 22px;
  margin-left: 20px;
  background-color: ${STYLES_CONSTANTS.COLORS.WHITE_FIVE};
  border-radius: 100%;
`;

const SContent = styled.div`
  flex: 1;
  margin: 30px 20px;
  position: relative;
  overflow: hidden;
`;

const SContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 30px;
  top: 0;
  left: 0;
`;

const STopBarItemWrapper = styled.div`
  margin-left: auto;
  display: flex;
`;

const SSectionTitle = styled.div`
  height: 42.13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0.5;
`;
const SInfo = styled.div`
  padding: 40px;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(10px) brightness(0.9);
  backdrop-filter: blur(10px) brightness(0.9);
  position: fixed;

  left: 50%;
  transform: translate(-50%, 0);
  z-index: 99;
  align-self: center;

  alignment-baseline: middle;
  & h1 {
    text-align: center;
    margin-top: 15px;
    margin-left: 15px;
    font-family: "Financier", sans-serif !important;
    & span {
      font-size: 1.7em;
      font-family: "FinancierBold", sans-serif !important;
    }
    color: white;
    font-size: 1.3em;
  }
`;
export const SkeletonOrigin = ({ noSidebar, noTopBar, needsPermissions }) => {
  return (
    <SOrigin>
      {needsPermissions && (
        <SInfo>
          <h1>
            <span>Please Wait</span>
            <br />
            You'll be redirected in a moment.
          </h1>
        </SInfo>
      )}
      {!noSidebar && <SidebarSkeleton />}
      <SBody>
        {!noTopBar && (
          <Fragment>
            <STopBar>
              <SkeletonText height="8px" marginBottom="6px" width="170px" />
              <STopBarItemWrapper>
                <STopBarItem />
                <STopBarItem />
                <STopBarItem />
              </STopBarItemWrapper>
            </STopBar>
            <STopBar color="rgb(243 243 243 / 95%)">
              <SkeletonText height="8px" marginBottom="6px" width="125px" />
            </STopBar>
          </Fragment>
        )}
        <SContent>
          <SContentWrapper>
            <SSectionTitle>
              <SkeletonText height="8px" marginBottom="6px" width="170px" />
              <SkeletonText height="8px" width="145px" />
            </SSectionTitle>
            <NewsCarouselSkeleton />
            <NewsGridSkeleton />
          </SContentWrapper>
        </SContent>
      </SBody>
    </SOrigin>
  );
};

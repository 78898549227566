import { TYPES } from 'actionTypes/searchBy';

export const addTag = (tag) => ({
    type: TYPES.ADD_TAG,
    payload: tag
});

export const deleteTag = (id) => ({
    type: TYPES.DELETE_TAG,
    payload: id
});

export const clearTags = () => ({
    type: TYPES.CLEAR_TAGS
});
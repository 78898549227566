import { WORKSPACES_ACTION_TYPES } from "actionTypes/workspaces";

const defaultState = {
  getAdminSharedFoldersPending: false,
  createAdminSharedFolderPending: false,
  updateAdminSharedFolderPending: false,
  deleteAdminSharedFolderPending: false,
  getSharedFoldersPending: false,
  createSharedFilePending: false,
  updateSharedFilePending: false,
  deleteSharedFilePending: false,
  getSharedFolderPending: false,
  adminSharedFolders: {
    results: [],
    count: 0,
  },
  sharedFolders: {
    results: [],
  },
  folder: {
    name: "",
    description: "",
    groups: [],
    children: [],
  },
};

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case WORKSPACES_ACTION_TYPES.GET_ADMIN_SHARED_FOLDERS.PENDING:
      return {
        ...state,
        getAdminSharedFoldersPending: true,
      };
    case WORKSPACES_ACTION_TYPES.GET_ADMIN_SHARED_FOLDERS.FULFILLED:
      return {
        ...state,
        getAdminSharedFoldersPending: false,
        adminSharedFolders: action.payload,
      };
    case WORKSPACES_ACTION_TYPES.GET_ADMIN_SHARED_FOLDERS.REJECTED:
      return {
        ...state,
        getAdminSharedFoldersPending: false,
      };

    case WORKSPACES_ACTION_TYPES.CREATE_ADMIN_SHARED_FOLDER.PENDING:
      return {
        ...state,
        createAdminSharedFolderPending: true,
      };
    case WORKSPACES_ACTION_TYPES.CREATE_ADMIN_SHARED_FOLDER.FULFILLED:
      return {
        ...state,
        createAdminSharedFolderPending: false,
        createAdminSharedFolder: action.payload,
      };
    case WORKSPACES_ACTION_TYPES.CREATE_ADMIN_SHARED_FOLDER.REJECTED:
      return {
        ...state,
        createAdminSharedFolderPending: false,
      };

    case WORKSPACES_ACTION_TYPES.UPDATE_ADMIN_SHARED_FOLDER.PENDING:
      return {
        ...state,
        updateAdminSharedFolderPending: true,
      };
    case WORKSPACES_ACTION_TYPES.UPDATE_ADMIN_SHARED_FOLDER.FULFILLED:
      return {
        ...state,
        updateAdminSharedFolderPending: false,
        updateAdminSharedFolder: action.payload,
      };
    case WORKSPACES_ACTION_TYPES.UPDATE_ADMIN_SHARED_FOLDER.REJECTED:
      return {
        ...state,
        updateAdminSharedFolderPending: false,
      };

    case WORKSPACES_ACTION_TYPES.DELETE_ADMIN_SHARED_FOLDER.PENDING:
      return {
        ...state,
        deleteAdminSharedFolderPending: true,
      };
    case WORKSPACES_ACTION_TYPES.DELETE_ADMIN_SHARED_FOLDER.FULFILLED:
      return {
        ...state,
        deleteAdminSharedFolderPending: false,
        deleteAdminSharedFolder: action.payload,
      };
    case WORKSPACES_ACTION_TYPES.DELETE_ADMIN_SHARED_FOLDER.REJECTED:
      return {
        ...state,
        deleteAdminSharedFolderPending: false,
      };

    case WORKSPACES_ACTION_TYPES.GET_SHARED_FOLDERS.PENDING:
      return {
        ...state,
        getSharedFoldersPending: true,
      };
    case WORKSPACES_ACTION_TYPES.GET_SHARED_FOLDERS.FULFILLED:
      return {
        ...state,
        getSharedFoldersPending: false,
        sharedFolders: action.payload,
      };
    case WORKSPACES_ACTION_TYPES.GET_SHARED_FOLDERS.REJECTED:
      return {
        ...state,
        getSharedFoldersPending: false,
      };

    case WORKSPACES_ACTION_TYPES.CREATE_SHARED_FILE.PENDING:
      return {
        ...state,
        createSharedFilePending: true,
      };
    case WORKSPACES_ACTION_TYPES.CREATE_SHARED_FILE.FULFILLED:
      return {
        ...state,
        createSharedFilePending: false,
        createSharedFile: action.payload,
      };
    case WORKSPACES_ACTION_TYPES.CREATE_SHARED_FILE.REJECTED:
      return {
        ...state,
        createSharedFilePending: false,
      };

    case WORKSPACES_ACTION_TYPES.UPDATE_SHARED_FILE.PENDING:
      return {
        ...state,
        updateSharedFilePending: true,
      };
    case WORKSPACES_ACTION_TYPES.UPDATE_SHARED_FILE.FULFILLED:
      return {
        ...state,
        updateSharedFilePending: false,
        updateSharedFile: action.payload,
      };
    case WORKSPACES_ACTION_TYPES.UPDATE_SHARED_FILE.REJECTED:
      return {
        ...state,
        updateSharedFilePending: false,
      };

    case WORKSPACES_ACTION_TYPES.DELETE_SHARED_FILE.PENDING:
      return {
        ...state,
        deleteSharedFilePending: true,
      };
    case WORKSPACES_ACTION_TYPES.DELETE_SHARED_FILE.FULFILLED:
      return {
        ...state,
        deleteSharedFilePending: false,
        deleteSharedFile: action.payload,
      };
    case WORKSPACES_ACTION_TYPES.DELETE_SHARED_FILE.REJECTED:
      return {
        ...state,
        deleteSharedFilePending: false,
      };

    case WORKSPACES_ACTION_TYPES.GET_SHARED_FOLDER.PENDING:
      return {
        ...state,
        getSharedFolderPending: true,
      };
    case WORKSPACES_ACTION_TYPES.GET_SHARED_FOLDER.FULFILLED:
      return {
        ...state,
        getSharedFolderPending: false,
        folder: action.payload,
      };
    case WORKSPACES_ACTION_TYPES.GET_SHARED_FOLDER.CLEAR:
      return {
        ...state,
        getSharedFolderPending: false,
        folder: {
          name: "",
          description: "",
          groups: [],
          children: [],
        },
      };
    case WORKSPACES_ACTION_TYPES.GET_SHARED_FOLDER.REJECTED:
      return {
        ...state,
        getSharedFolderPending: false,
      };
    default:
      return state;
  }
};

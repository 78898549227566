import React from 'react';
import styled from 'styled-components';


const SLogoWrapper = styled.div`
    margin-bottom: 80px;
`;

const SLogo = styled.img`
    width: 300px;
`;

export const Logo = () => (
    <SLogoWrapper>
        <SLogo src="/assets/svg/zone_logo.svg" alt="CBRE logo" />
    </SLogoWrapper>
);

/**
 * Created by kurtwagner on 16.04.2018
 */
import { COLORS } from "./colors";
import { GRADIENTS } from "./gradients";
import { ZINDEX } from "./zIndex";
import { FONTSIZES } from "./fontSizes";
export const STYLES_CONSTANTS = {
  COLORS,
  GRADIENTS,
  ZINDEX,
  FONTSIZE: {
    default: "17px",
    ...FONTSIZES,
  },
};
